import { useEffect, useMemo } from 'react'

import mixpanel from 'mixpanel-browser'
import { UNIDENTIFIED_EVENT } from '@avalanche-canada/constants/products/mixpanel'

// Adapted from: https://stackoverflow.com/a/65008608
const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [0.25],
}

/*
This hook address two scenarios:
1. When the user scrolls to a section and the section is visible, the hook starts timing the duration the section is visible before scrolling away.
2. Navigating away from the page or tab switch, the hook stops timing and tracks the duration the section was visible.
*/

export const useTrackSectionTimeOnScreen = ref => {
    const observer = useMemo(
        () =>
            // Scenario 1
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    // Start time when element is first visible
                    const now = new Date().getTime()
                    entry.target.dataset.startTime = now
                } else {
                    // Element is no longer visible; stop timing and track duration
                    trackViewDuration(entry.target.dataset)

                    // Reset the timer
                    delete entry.target.dataset.startTime
                }
            }, observerOptions),
        [ref]
    )

    useEffect(() => {
        observer.observe(ref.current)
        window.addEventListener('visibilitychange', () => handleVisibilityChange(ref.current))

        return () => {
            observer.disconnect()
            window.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])
}

// Scenario 2
const handleVisibilityChange = entry => {
    if (!entry) return

    // Page becomes hidden by tab switch or close; stop timing and track duration
    if (document.visibilityState === 'hidden') {
        trackViewDuration(entry.dataset)
        // Reset the timer
        delete entry.dataset?.startTime
    }
}

const trackViewDuration = dataset => {
    const startTime = dataset?.startTime

    if (!startTime) return

    const endTime = new Date().getTime()
    // Round to nearest 0.01s
    const duration = Math.round((endTime - startTime) / 10) / 100

    const trackingObject = {
        duration_s: duration,
        ...dataset, // Add any properties per the dataset (e.g. `data-*` on the HTML element)
    }
    delete trackingObject.startTime // Clear this since duration covers it
    delete trackingObject.eventName // Clear this since it is redundant

    const eventName = dataset.eventName || UNIDENTIFIED_EVENT

    mixpanel.track(eventName, trackingObject)
}
