import React, { useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'

import { Label } from 'components/MINReporting/Label'

export const CheckboxGroup = ({
    options,
    label,
    selected,
    onChange,
    singleRow,
    singleValue,
    icon,
}) => {
    // const [checked, setChecked] = useState(selected)
    const optionsMiddle = Math.ceil(options.length / 2)

    const updateSelections = (val, key) => {
        if (singleValue) {
            if (val) {
                onChange(key)
            } else {
                onChange()
            }
        } else {
            if (val) {
                onChange([...selected, key])
            } else {
                onChange(selected.filter(item => item !== key))
            }
        }
    }

    const formattedCheckboxes = list => {
        return list.map(item => {
            return (
                <div key={item.key} style={singleRow ? styles.longOption : null}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    singleValue
                                        ? selected === item.key
                                        : selected.includes(item.key)
                                }
                                onChange={event => updateSelections(event.target.checked, item.key)}
                            />
                        }
                        label={item.label}
                    />
                </div>
            )
        })
    }

    return (
        <FormControl fullWidth>
            <Label text={label} icon={icon} />
            <Grid style={styles.grid} container>
                {singleRow ? (
                    <Grid item>{formattedCheckboxes(options)}</Grid>
                ) : (
                    <>
                        <Grid xs={6} item>
                            {formattedCheckboxes(options.slice(0, optionsMiddle))}
                        </Grid>
                        <Grid xs={6} item>
                            {formattedCheckboxes(options.slice(optionsMiddle, options.length))}
                        </Grid>
                    </>
                )}
            </Grid>
        </FormControl>
    )
}

const styles = {
    grid: {
        marginTop: '5px',
    },
    longOption: {
        marginBottom: '6px',
    },
}
