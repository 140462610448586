import { FormattedMessage } from 'react-intl'

// Weather Report section
export const CLOUD_COVER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Cloud cover" />,
    path: 'observations.weather.skyCondition',
    options: [
        {
            key: 'clear',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Clear" />,
        },
        {
            key: 'fewClouds',
            label: (
                <FormattedMessage description="MIN Form Field" defaultMessage="Few Clouds (<2/8)" />
            ),
        },
        {
            key: 'scatteredClouds',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Scattered clouds (2/8-4/8)"
                />
            ),
        },
        {
            key: 'brokenClouds',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Broken Clouds (5/8-7/8)"
                />
            ),
        },
        {
            key: 'overcast',
            label: (
                <FormattedMessage description="MIN Form Field" defaultMessage="Overcast (8/8)" />
            ),
        },
        {
            key: 'fog',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Fog" />,
        },
    ],
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Values expressed in eighths refer to the proportion of the sky that was covered with clouds. E.g. 2/8 refers to a sky approximately one quarter covered with cloud."
            />
        ),
    },
}

export const PRECIPTATION_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Precipitation type" />,
    path: 'observations.weather.precipitationType',
    options: [
        {
            key: 'snow',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Snow" />,
        },
        {
            key: 'rain',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Rain" />,
        },
        {
            key: 'mixedSnowRain',
            label: (
                <FormattedMessage description="MIN Form Field" defaultMessage="Mixed snow & rain" />
            ),
        },
        {
            key: 'none',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="None" />,
        },
    ],
}

export const SNOWFALL_RATE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snowfall rate (cm/hr)" />,
    path: 'observations.weather.snowfallRate',
    min: 1,
    max: 20,
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="If there was no snow, please leave this field blank."
            />
        ),
    },
}

export const RAINFALL_RATE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Rainfall rate" />,
    path: 'observations.weather.rainfallRate',
    options: [
        {
            key: 'drizzle',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Drizzle" />,
        },
        {
            key: 'showers',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Showers" />,
        },
        {
            key: 'raining',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Raining" />,
        },
        {
            key: 'pouring',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Pouring" />,
        },
    ],
}

export const TEMPERATURE = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Temperature at time of observation (°C)"
        />
    ),
    path: 'observations.weather.avgTemp',
    min: -50,
    max: 40,
}

export const MIN_TEMPERATURE = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Minimum temperature in the last 24 hours (°C)."
        />
    ),
    path: 'observations.weather.minTemp',
    min: -50,
    max: 30,
}

export const MAX_TEMPERATURE = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Maximum temperature in the last 24 hours (°C)."
        />
    ),
    path: 'observations.weather.maxTemp',
    min: -40,
    max: 40,
}

export const TEMPERATURE_CHANGE = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Describe how the temperature changed in the last 3 hours"
        />
    ),
    path: 'observations.weather.temperatureTrend',
    options: [
        {
            key: 'falling',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Falling" />,
        },
        {
            key: 'steady',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Steady" />,
        },
        {
            key: 'rising',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Rising" />,
        },
    ],
}

export const NEW_SNOW = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Amount of new snow in last 24 hours (cm)"
        />
    ),
    path: 'observations.weather.newSnow24Hours',
    min: 0,
    max: 100,
}

export const NEW_SNOW_AND_RAIN = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Total rain and snow combined in last 24 hours (mm)"
        />
    ),
    path: 'observations.weather.precipitation24Hours',
    min: 0,
    max: 100,
}

export const RECENT_STORM_SNOW = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="Total snow from the most recent storm (cm)"
        />
    ),
    path: 'observations.weather.stormSnowAmount',
    min: 0,
    max: 300,
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Please enter the amount of snow that has fallen during the current storm cycle. You can specify a storm start date to describe the time period over which this snow fell."
            />
        ),
    },
}

export const RECENT_STORM_DATE = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="The date on which the most recent storm started"
        />
    ),
    path: 'observations.weather.stormStartDate',
    required: true,
    description: {
        info: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="The date on which the most recent storm started. Leave blank if there has not been a recent storm."
            />
        ),
    },
}

export const WIND_SPEED = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Wind speed" />,
    path: 'observations.weather.windSpeed',
    options: [
        {
            key: 'windSpeedCalm',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Calm" />,
        },
        {
            key: 'windSpeedLight',
            label: (
                <FormattedMessage description="MIN Form Field" defaultMessage="Light (1-25 km/h)" />
            ),
        },
        {
            key: 'windSpeedModerate',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Moderate (26-40 km/h)"
                />
            ),
        },
        {
            key: 'windSpeedStrong',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Strong (41-60 km/h)"
                />
            ),
        },
        {
            key: 'windSpeedExtreme',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Extreme (>60 km/h)"
                />
            ),
        },
    ],
    description: {
        type: 'bullets',
        info: [
            <FormattedMessage description="MIN Form Field" defaultMessage="Calm: smoke rises" />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Light: flags and twigs move"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Moderate: snow begins to drift"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Strong: whole tress in motion"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Extreme: difficulty walking"
            />,
        ],
    },
}

export const WIND_DIRECTION = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Wind direction" />,
    path: 'observations.weather.windDirection',
    options: [
        { key: 'N', label: <FormattedMessage description="MIN Form Field" defaultMessage="N" /> },
        { key: 'NE', label: <FormattedMessage description="MIN Form Field" defaultMessage="NE" /> },
        { key: 'E', label: <FormattedMessage description="MIN Form Field" defaultMessage="E" /> },
        { key: 'SE', label: <FormattedMessage description="MIN Form Field" defaultMessage="SE" /> },
        { key: 'S', label: <FormattedMessage description="MIN Form Field" defaultMessage="S" /> },
        { key: 'SW', label: <FormattedMessage description="MIN Form Field" defaultMessage="SW" /> },
        { key: 'W', label: <FormattedMessage description="MIN Form Field" defaultMessage="W" /> },
        { key: 'NW', label: <FormattedMessage description="MIN Form Field" defaultMessage="NW" /> },
    ],
}

export const BLOWING_SNOW = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Blowing snow" />,
    path: 'observations.weather.blowingSnow',
    options: [
        {
            key: 'none',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="None" />,
        },
        {
            key: 'light',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Light" />,
        },
        {
            key: 'moderate',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Moderate" />,
        },
        {
            key: 'intense',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Intense" />,
        },
    ],
    description: {
        title: (
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="How much snow is blowing at ridge crest elevation."
            />
        ),
        type: 'bullets',
        info: [
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Light: localized snow drifting"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Moderate: a plume of snow is visible"
            />,
            <FormattedMessage
                description="MIN Form Field"
                defaultMessage="Intense: a large plume moving snow well down the slope"
            />,
        ],
    },
}

export const COMMENT = {
    path: 'observations.weather.comment',
}

export const WEATHER_FIELDS = [
    CLOUD_COVER,
    PRECIPTATION_TYPE,
    SNOWFALL_RATE,
    RAINFALL_RATE,
    TEMPERATURE,
    MIN_TEMPERATURE,
    MAX_TEMPERATURE,
    TEMPERATURE_CHANGE,
    NEW_SNOW,
    NEW_SNOW_AND_RAIN,
    RECENT_STORM_SNOW,
    RECENT_STORM_DATE,
    WIND_SPEED,
    WIND_DIRECTION,
    BLOWING_SNOW,
    COMMENT,
]
