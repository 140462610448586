import { FormattedMessage } from 'react-intl'

// Quick Report Section
export const RIDING_QUALITY = {
    path: 'observations.quick.ridingQuality',
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Riding quality was" />,
    options: [
        {
            key: 'amazing',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Amazing" />,
        },
        {
            key: 'good',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Good" />,
        },
        { key: 'ok', label: <FormattedMessage description="MIN Form Field" defaultMessage="Ok" /> },
        {
            key: 'terrible',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Terrible" />,
        },
    ],
}

export const SNOW_CONDITIONS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Snow conditions were" />,
    path: 'observations.quick.snowConditions',
    options: [
        {
            key: 'deepPowder',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Deep Powder" />,
        },
        {
            key: 'wet',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Wet" />,
        },
        {
            key: 'crusty',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Crusty" />,
        },
        {
            key: 'powder',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Powder" />,
        },
        {
            key: 'heavy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Heavy" />,
        },
        {
            key: 'windAffected',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Wind Affected" />,
        },
        {
            key: 'hard',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Hard" />,
        },
    ],
}

export const RIDE_TYPE = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="We rode" />,
    path: 'observations.quick.rideType',
    options: [
        {
            key: 'sunnySlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Sunny Slopes" />,
        },
        {
            key: 'convexSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Convex Slopes" />,
        },
        {
            key: 'steepSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Steep Slopes" />,
        },
        {
            key: 'alpineSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Alpine Slopes" />,
        },
        {
            key: 'cutBlocks',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cut Blocks" />,
        },
        {
            key: 'mellowSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Mellow Slopes" />,
        },
        {
            key: 'denseTrees',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Dense Trees" />,
        },
        {
            key: 'openTrees',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Open Trees" />,
        },
    ],
}

export const STAYED_AWAY = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="We stayed away from" />,
    path: 'observations.quick.stayedAway',
    options: [
        {
            key: 'steepSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Steep Slopes" />,
        },
        {
            key: 'sunnySlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Sunny Slopes" />,
        },
        {
            key: 'convexSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Convex Slopes" />,
        },
        {
            key: 'alpineSlopes',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Alpine Slopes" />,
        },
        {
            key: 'cutBlocks',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cut Blocks" />,
        },
        {
            key: 'openTrees',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Open Trees" />,
        },
    ],
}

export const AVALANCHE_CONDITIONS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Avalanche conditions" />,
    path: 'observations.quick.avalancheConditions',
    options: [
        {
            key: 'avalancheConditionSnow',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="30cm + of new snow, or significant drifting, or rain in the last 48 hours."
                />
            ),
        },
        {
            key: 'avalancheConditionSlab',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Slab avalanches today or yesterday."
                />
            ),
        },
        {
            key: 'avalancheConditionSound',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Whumpfing or drum-like sounds or shooting cracks."
                />
            ),
        },
        {
            key: 'avalancheConditionTemp',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Rapid temperature rise to near or above 0°C or wet surface snow."
                />
            ),
        },
    ],
}

export const WEATHER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="The day was:" />,
    path: 'observations.quick.weather',
    options: [
        {
            key: 'warm',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Warm" />,
        },
        {
            key: 'foggy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Foggy" />,
        },
        {
            key: 'cloudy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cloudy" />,
        },
        {
            key: 'stormy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Stormy" />,
        },
        {
            key: 'windy',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Windy" />,
        },
        {
            key: 'cold',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Cold" />,
        },
        {
            key: 'sunny',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Sunny" />,
        },
        {
            key: 'wet',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Wet" />,
        },
    ],
}

export const ICE_QUALITY = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Ice quality was" />,
    path: 'observations.quick.iceQuality',
    options: [
        {
            key: 'amazing',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Amazing" />,
        },
        {
            key: 'good',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Good" />,
        },
        { key: 'ok', label: <FormattedMessage description="MIN Form Field" defaultMessage="Ok" /> },
        {
            key: 'terrible',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Terrible" />,
        },
    ],
}

export const WIND_CLIMBING_CONDITIONS = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Wind was" />,
    path: 'observations.quick.climbingWindConditions',
    options: [
        {
            key: 'blowingSnowOnTheClimb',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Blowing snow on the climb"
                />
            ),
        },
        {
            key: 'blowingSnowAboveTheClimb',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Blowing snow above the climb"
                />
            ),
        },
        {
            key: 'blowingSnowAcrossTheValley',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Blowing snow across the valley"
                />
            ),
        },
        {
            key: 'formingCornices',
            label: (
                <FormattedMessage description="MIN Form Field" defaultMessage="Forming cornices" />
            ),
        },
    ],
}

export const RETREAT_CLIMB_REASON = {
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="We retreated from the climb because of"
        />
    ),
    path: 'observations.quick.retreatedFromClimbBecauseOf',
    options: [
        {
            key: 'poorIceConditions',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Poor ice conditions"
                />
            ),
        },
        {
            key: 'warmingSolarRadiation',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Warming/solar radiation"
                />
            ),
        },
        {
            key: 'inversionConditions',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Inversion conditions"
                />
            ),
        },
        {
            key: 'strongWinds',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Strong winds" />,
        },
        {
            key: 'spindrift',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Spindrift" />,
        },
        {
            key: 'otherPartiesOnClimb',
            label: (
                <FormattedMessage
                    description="MIN Form Field"
                    defaultMessage="Other parties on the climb"
                />
            ),
        },
        {
            key: 'timeOfDay',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Time of day" />,
        },
        {
            key: 'humanFactors',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Human factors" />,
        },
        {
            key: 'other',
            label: <FormattedMessage description="MIN Form Field" defaultMessage="Other" />,
        },
    ],
}

export const RETREAT_CLIMB_REASON_OTHER = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Describe other reason" />,
    path: 'observations.quick.retreatedFromClimbBecauseOfOther',
    required: false,
}

export const CLIMB_NAME = {
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Climb name" />,
    path: 'observations.quick.climbName',
    required: false,
}

export const COMMENT = {
    path: 'observations.quick.comment',
}

export const QUICK_FIELDS = [
    RIDING_QUALITY,
    SNOW_CONDITIONS,
    RIDE_TYPE,
    STAYED_AWAY,
    AVALANCHE_CONDITIONS,
    WEATHER,
    ICE_QUALITY,
    WIND_CLIMBING_CONDITIONS,
    RETREAT_CLIMB_REASON,
    CLIMB_NAME,
    COMMENT,
]
