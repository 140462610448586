import React, { useRef } from 'react'

import { BottomSheet as ReactSpringBottomSheet } from 'react-spring-bottom-sheet'

import { AppNav } from 'components/AppNav/AppNav'
import { useSetBottomSheetRef, DRAWER_FOOTER_OFFSET } from 'stores/BottomSheetStore'

export const BottomSheet = ({ defaultSnap, layout, setLayout, children }) => {
    const ref = useRef()
    const setRef = useSetBottomSheetRef()
    setRef(ref)

    return (
        <>
            <ReactSpringBottomSheet
                ref={ref}
                open
                blocking={false}
                defaultSnap={defaultSnap}
                expandOnContentDrag={true}
                snapPoints={({ footerHeight, maxHeight }) => [
                    maxHeight, // Full height
                    maxHeight * 0.5, // Half height
                    maxHeight * 0.25, // Quarter height
                    footerHeight + DRAWER_FOOTER_OFFSET, // Closed with only pull-tab visible
                ]}
                footer={
                    <div
                        style={{
                            height: '56px',
                            width: '100%',
                        }}></div>
                }>
                {children}
            </ReactSpringBottomSheet>
            <AppNav layout={layout} setLayout={setLayout} />
        </>
    )
}
