import { FormattedMessage } from 'react-intl'
import ReactHtmlParser from 'react-html-parser'

import { useMedia, useReport } from './Context'
import { useSetExplorerView, useSetLayout, useSetProductID } from 'stores/ViewStore'
import { EXPLORER_LAYOUT } from 'constants/layout'
import { replaceAnchorTagsRecursive } from 'utils/url'
import { ImageSet } from './ImageSet'
import { Summary } from './Summary'

export const DetailSet = () => {
    const { summaries } = useReport()
    const { images } = useMedia()

    const setLayout = useSetLayout()
    const setExplorerView = useSetExplorerView()
    const setProductID = useSetProductID()

    if (!Array.isArray(summaries) || summaries.length === 0) {
        return (
            <h3>
                <FormattedMessage
                    description="Layout product/forecast/details"
                    defaultMessage="No details found."
                />
            </h3>
        )
    }

    const handleOnClick = (id, type) => {
        setLayout(EXPLORER_LAYOUT)
        setExplorerView(type)
        setProductID(id)
    }

    return summaries.map(({ type, content }) => {
        const jsx = ReactHtmlParser(content)

        // replace the anchor tags using the recursive function
        jsx.forEach(element => {
            replaceAnchorTagsRecursive(element, handleOnClick)
        })

        return (
            <Summary key={type.value} title={type.display}>
                {jsx}
                <ImageSet type={type.value} images={images && images.length
                    ? images.filter(image => image.tag === typeToTagMap.get(type.value))
                    : []} />
            </Summary>
        )
    })
}

// Utils
export const typeToTagMap = new Map([
    ['avalanche-summary', 'avalanche'],
    ['weather-summary', 'weather'],
    ['snowpack-summary', 'snowpack'],
])
