import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import MapIcon from '@mui/icons-material/Map'

import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { EXPLORER_LAYOUT, MIN_LAYOUT } from 'constants/layout'
import { toggleBottomSheetHalfHeight, toggleBottomSheetFullHeight } from 'stores/BottomSheetStore'
import { useExplorerView } from 'stores/ViewStore'
import { EXPLORER_VIEW } from 'constants/layout'

export const AppNav = ({ layout, setLayout }) => {
    const router = useRouter()
    const explorerView = useExplorerView()
    const handleExploreClick = () => {
        if (explorerView === EXPLORER_VIEW) {
            toggleBottomSheetHalfHeight() // only toggle when on the default view
            if (router.query.canGoBack !== 'true') {
                addCanGoBack(router)
            }
        }
        setLayout(EXPLORER_LAYOUT)
    }
    const handleMINClick = () => {
        toggleBottomSheetFullHeight() // only toggle when on the default view
        setLayout(MIN_LAYOUT)
    }

    return (
        <div style={style.nav}>
            <BottomNavigation showLabels value={layout}>
                <BottomNavigationAction
                    value={MIN_LAYOUT}
                    label={
                        <FormattedMessage defaultMessage="New MIN Report" description="Main Nav" />
                    }
                    onClick={handleMINClick}
                    icon={<AddLocationAltIcon />}
                />
                <BottomNavigationAction
                    value={EXPLORER_LAYOUT}
                    label={<FormattedMessage defaultMessage="Explorer" description="Main Nav" />}
                    icon={<MapIcon />}
                    onClick={handleExploreClick}
                />
            </BottomNavigation>
        </div>
    )
}

const style = {
    nav: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 9999,
        paddingBottom: 'env(safe-area-inset-bottom)',
        background: 'var(--background-color)',
        borderTop: '1px solid var(--color-step-150)',
    },
}

// utils
const addCanGoBack = router => {
    router.push(
        {
            pathname: '/',
            query: { canGoBack: true },
        },
        null,
        { shallow: true }
    )
}
