import React, { useState, useEffect } from 'react'

import { Box, LinearProgress, Snackbar, Alert } from '@mui/material'
import { set, unset } from 'lodash'

import { useAuth, useLogin } from 'contexts/authentication'
import { useSaveSubmission } from 'hooks/data/userMINs'
import { VALID_FIELDS } from 'components/MINReporting/constants/general'
import {
    REPORT_TYPES_DESCRIPTIONS,
    REPORT_TYPES,
    TAGS,
    REPORT_STEPS,
    SNOW_CONDITIONS_FIELDS,
    ICE_CONDITIONS_FIELDS,
    ERROR_MESSAGES,
} from 'components/MINReporting/constants/general'
import { AUTO_HIDE_DURATION, TOP_CENTER } from 'constants/snackbar'
import { Spacer } from 'components/Spacer/Spacer'
import { MINStepper } from 'components/MINReporting/MINStepper'
import Shim from 'components/Shim/Shim'
import {
    isValidReport,
    calculateProgress,
    generateNewReportData,
} from 'components/MINReporting/helpers'
import { Form } from 'components/MINReporting/Form'
import { Review } from 'components/MINReporting/Review'
import { Submit } from 'components/MINReporting/Submit'
import { useMap } from 'contexts/map'

export const NewMINReport = ({
    locationUpdate,
    setLocationUpdate,
    setLayoutWithProduct,
    runClearValuesSideEffects,
    initialReportValue,
    reportData,
    setReportData,
}) => {
    const map = useMap()
    const { isAuthenticated } = useAuth()
    const login = useLogin()
    const saveSubmission = useSaveSubmission()

    // Report specific states
    const [progress, setProgress] = useState(0)
    const [reportType, setReportType] = useState(REPORT_TYPES.QUICK)
    const [activeStep, setActiveStep] = useState(0)
    const [reportDescription, setReportDescription] = useState(
        REPORT_TYPES_DESCRIPTIONS[reportType]
    )

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState(null)
    const [snackbarSeverity, setSnackbarSeverity] = useState('error')
    const [pendingSubmit, setPendingSubmit] = useState(false)

    // Updating report data
    const updateReportType = type => {
        setReportType(type)
        setReportDescription(REPORT_TYPES_DESCRIPTIONS[type])
        const newProgress = calculateProgress(reportData, type)
        setProgress(newProgress)
    }

    const updateReport = (value, path) => {
        // If the user is changing a snow conditions field then we have to clear the ice conditions fields
        if (SNOW_CONDITIONS_FIELDS.includes(path)) {
            set(reportData, TAGS.path, [TAGS.SNOW_CONDITIONS])
            ICE_CONDITIONS_FIELDS.forEach(path => {
                unset(reportData, path)
            })
        }

        // If the user is changing a ice conditions field then we have to clear the snow conditions fields
        if (ICE_CONDITIONS_FIELDS.includes(path)) {
            set(reportData, TAGS.path, [TAGS.ICE_CONDITIONS])
            SNOW_CONDITIONS_FIELDS.forEach(path => {
                unset(reportData, path)
            })
        }

        const newReportData = generateNewReportData({ ...reportData }, value, path)
        const newProgress = calculateProgress(newReportData, reportType)

        setReportData(newReportData)
        setProgress(newProgress)
    }

    // Steps in the submission process
    const handleClickReviewButton = () => {
        if (!isValidReport(reportData)) {
            setSnackbarMessage(ERROR_MESSAGES.errorMissingFields)
            setSnackbarSeverity('error')
            setShowSnackbar(true)
            return
        }

        // Set the activeStep
        setActiveStep(REPORT_STEPS.REVIEW.value)
    }

    const handleClickClearButton = () => {
        setReportData(initialReportValue)
        if (map && map.currentMarker) {
            map.currentMarker.remove()
        }
        runClearValuesSideEffects()
    }

    const checkAuthAndSubmit = () => {
        if (!isAuthenticated) {
            setPendingSubmit(true)
            setSnackbarMessage(ERROR_MESSAGES.errorNotLoggedIn)
            setSnackbarSeverity('info')
            setShowSnackbar(true)
            login()
        } else {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        // Validate and clean up data
        let validReportData = { ...reportData }
        Object.keys(validReportData).forEach(key => {
            if (!VALID_FIELDS.includes(key)) {
                delete validReportData[key]
            }
        })

        if (navigator.onLine === false) {
            setSnackbarMessage(ERROR_MESSAGES.offline)
            setSnackbarSeverity('error')
            setShowSnackbar(true)
        } else {
            // Save the report
            try {
                const response = await saveSubmission(validReportData)
                if (response) {
                    // Save the submission id to the MIN
                    const newReportData = { ...reportData, submissionID: response.submissionID }
                    setReportData(newReportData)

                    // Redirect to the submission success page
                    setActiveStep(REPORT_STEPS.SUBMIT.value)
                }
            } catch (error) {
                setSnackbarMessage(ERROR_MESSAGES.errorSubmitting)
                setSnackbarSeverity('error')
                setShowSnackbar(true)
            }
        }
    }

    useEffect(() => {
        if (locationUpdate) {
            updateReport(locationUpdate, 'location')
            setLocationUpdate(null)
        }
    }, [locationUpdate, updateReport, reportData])

    useEffect(() => {
        if (pendingSubmit && isAuthenticated) {
            setPendingSubmit(false)
            setShowSnackbar(false)
            handleSubmit()
        }
    }, [isAuthenticated])

    return (
        <>
            <div style={styles.container}>
                <Spacer />
                <Shim horizontal>
                    <MINStepper activeStep={activeStep} />
                </Shim>
                <Spacer />
                {activeStep === REPORT_STEPS.FORM.value && (
                    <Form
                        reportType={reportType}
                        updateReportType={updateReportType}
                        reportDescription={reportDescription}
                        updateReport={updateReport}
                        reportData={reportData}
                        handleClickReviewButton={handleClickReviewButton}
                        handleClickClearButton={handleClickClearButton}
                    />
                )}
                {activeStep === REPORT_STEPS.REVIEW.value && (
                    <Review
                        reportData={reportData}
                        setActiveStep={setActiveStep}
                        handleSubmit={checkAuthAndSubmit}
                    />
                )}
                {activeStep === REPORT_STEPS.SUBMIT.value && (
                    <Submit
                        reportData={reportData}
                        setActiveStep={setActiveStep}
                        setLayoutWithProduct={setLayoutWithProduct}
                    />
                )}
                <Box style={styles.reportProgress} sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            </div>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={AUTO_HIDE_DURATION}
                anchorOrigin={TOP_CENTER}
                onClose={() => setShowSnackbar(false)}>
                <Alert severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

const styles = {
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    reportProgress: {
        position: 'fixed',
        bottom: 'calc(56px + env(safe-area-inset-bottom))',
        zIndex: 10000,
    },
}
