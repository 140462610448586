import { useEffect } from 'react'

import { FATAL_ACCIDENT_OPENED } from '@avalanche-canada/constants/products/mixpanel'
import mixpanel from 'mixpanel-browser'
import { FormattedMessage } from 'react-intl'

import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { Entry, Metadata } from 'components/metadata'
import { HeaderWithLocate } from 'components/text/HeaderWithLocate'
import { DateTime } from 'components/time'
import { useAccident } from 'hooks/data/accidents'
import { StructuredText } from 'prismic/components/base'

export const Accident = ({ uid }) => {
    const { accident } = useAccident(uid)

    useEffect(() => {
        mixpanel.track(FATAL_ACCIDENT_OPENED, { id: uid })
    }, [uid])

    if (!accident) {
        return (
            <>
                <Shim horizontal>
                    <FormattedMessage
                        defaultMessage="Accident not found"
                        description="Layout drawers/FatalAccident"
                    />
                </Shim>
            </>
        )
    }

    const { title, dateOfAccident, content } = accident.data
    const { latitude, longitude } = accident.data.location

    const term = (
        <FormattedMessage
            defaultMessage="Accident Date"
            description="Layout drawers/FatalAccident"
        />
    )

    return (
        <DetailsWrapper>
            <HeaderWithLocate text={title} location={[longitude, latitude]} />
            <Shim horizontal>
                <Metadata>
                    <Entry term={term}>
                        <DateTime value={dateOfAccident} />
                    </Entry>
                </Metadata>
                <StructuredText value={content} />
            </Shim>
        </DetailsWrapper>
    )
}
