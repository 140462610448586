import create from 'zustand'

import { DEFAULT_EXPLORER_VIEW, DEFAULT_HISTORY_VALUE, DEFAULT_LAYOUT } from 'constants/layout'

const ViewStore = create(set => {
    return {
        layout: DEFAULT_LAYOUT,
        explorerView: DEFAULT_EXPLORER_VIEW,
        productID: null,
        history: [DEFAULT_HISTORY_VALUE],
        nextBackShouldCloseSheet: false,
        editingMIN: null,

        setLayout: layout => {
            set({ layout })
        },
        setExplorerView: explorerView => {
            set({ explorerView })
        },
        setProductID: productID => {
            set({ productID })
        },
        goToHome: () => {
            set({
                layout: DEFAULT_LAYOUT,
                explorerView: DEFAULT_EXPLORER_VIEW,
                productID: null,
                nextBackShouldCloseSheet: true,
            })
        },
        setHistory: history => {
            set({ history })
        },
        setNextBackShouldCloseSheet: nextBackShouldCloseSheet => {
            set({ nextBackShouldCloseSheet })
        },
        setEditingMIN: editingMIN => {
            set({ editingMIN })
        },
    }
})

export const useLayout = () => ViewStore(state => state.layout)
export const useSetLayout = () => ViewStore(state => state.setLayout)
export const useExplorerView = () => ViewStore(state => state.explorerView)
export const useSetExplorerView = () => ViewStore(state => state.setExplorerView)
export const useProductID = () => ViewStore(state => state.productID)
export const useSetProductID = () => ViewStore(state => state.setProductID)
export const useGoToHome = () => ViewStore(state => state.goToHome)
export const useHistory = () => ViewStore(state => state.history)
export const useSetHistory = () => ViewStore(state => state.setHistory)
export const useNextBackShouldCloseSheet = () => ViewStore(state => state.nextBackShouldCloseSheet)
export const useSetNextBackShouldCloseSheet = () =>
    ViewStore(state => state.setNextBackShouldCloseSheet)
export const useEditingMIN = () => ViewStore(state => state.editingMIN)
export const useSetEditingMIN = () => ViewStore(state => state.setEditingMIN)
