import React, { useState, useRef, useEffect, useCallback } from 'react'

import { BiImageAdd } from 'react-icons/bi'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Snackbar, Alert, Modal, Box, TextField, Button } from '@mui/material'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { CldImage } from 'next-cloudinary'

import * as Config from '../../clients/config'
import { ListWrapper } from 'components/Motion/ListWrapper'
import { AUTO_HIDE_DURATION, TOP_CENTER } from 'constants/snackbar'
import { InfoLabel } from './InfoLabel'

const PLACEHOLDER =
    'https://images.avalanche.ca/min/image/200/2023%2F01%2F05%2Fb8767c99-3729-4d05-ac0e-4f0887094f1a.jpg'
const AVCAN_HEADERS = {
    headers: {
        'content-type': 'application/json',
        'X-API-KEY': Config.key,
    },
}
const AVCAN_URL = `${Config.api}/min/en/images`

export const ImageUpload = ({ onChange, reportImages = [] }) => {
    const fileInputRef = useRef()
    const [containerStyle, setContainerStyle] = useState(styles.imageIconContainer)
    const [showError, setShowError] = useState(false)
    const [images, setImages] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [imageModalOpen, setImageModalOpen] = useState(false)
    const [imageToBeCaptioned, setImageToBeCaptioned] = useState(null)
    const [caption, setCaption] = useState('')

    // Load initial images if editing a report
    useEffect(() => {
        if (reportImages && reportImages.length > 0 && !dataLoaded) {
            setDataLoaded(true)
            setImages(reportImages)
        }
    }, [reportImages, dataLoaded])

    // Image upload and remove functions
    const initUpload = () => {
        setContainerStyle(styles.imageIconContainerClicked)
        fileInputRef.current.click()
    }

    const uploadImages = async imageFiles => {
        const newImages = []

        for (const imageFile of imageFiles) {
            // Upload image to Cloudinary
            const formData = new FormData()
            formData.append('file', imageFile)
            formData.append('upload_preset', process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET)

            const cloudinaryResponse = await axios.post(
                process.env.NEXT_PUBLIC_CLOUDINARY_URL,
                formData
            )

            // handle error when uploading image
            if (!cloudinaryResponse.data || cloudinaryResponse.data.length === 0) {
                showErrorMsg()
                continue
            }

            // add image to new Images slated to be shown
            newImages.push({
                url: cloudinaryResponse.data.secure_url,
                id: cloudinaryResponse.data.public_id,
                height: cloudinaryResponse.data.height,
                width: cloudinaryResponse.data.width,
            })
        }

        return newImages
    }

    const imageSelectHandler = async event => {
        try {
            setContainerStyle(styles.imageIconContainer)

            // get image files
            const imageFilesArray = Array.from(event.target.files)
            const placeholderImagesArray = Array.from({ length: imageFilesArray.length }, () => {
                return { url: PLACEHOLDER }
            })
            setImages([...images, ...placeholderImagesArray])

            // upload images
            const newImages = await uploadImages(imageFilesArray)
            const imagesWithoutPlaceholders = images.filter(img => img.url !== PLACEHOLDER)

            setImages([...imagesWithoutPlaceholders, ...newImages])
            onChange([...imagesWithoutPlaceholders, ...newImages])

            // reset the file input
            fileInputRef.current.value = null
        } catch (e) {
            showErrorMsg()
            fileInputRef.current.value = null
        }
    }

    const removeImage = image => {
        const newImages = images.filter(img => img.id !== image.id)
        setImages(newImages)
        onChange(newImages)
    }

    // Image caption functions
    const handleImageClick = image => {
        setImageToBeCaptioned(image)
        setCaption(image.caption)
        setImageModalOpen(true)
    }

    const handleSubmitCaption = useCallback(() => {
        const newImages = images.map(img => {
            return img.id === imageToBeCaptioned.id ? { ...img, caption } : img
        })
        setImages(newImages)
        onChange(newImages)
        setImageModalOpen(false)
        setCaption('')
    }, [images, imageToBeCaptioned, caption, onChange])

    const handleCancelCaption = useCallback(() => {
        setImageModalOpen(false)
        setCaption('')
    }, [])

    // Utils
    const formatThumbnailImageUrl = url => url.replace('{size}', '70')

    const formatModalImageUrl = url => url.replace('{size}', '500')

    // handle errors
    const showErrorMsg = () => {
        setShowError(true)
        const newImages = images.filter(img => img.url !== PLACEHOLDER)
        setImages(newImages)
        onChange(newImages)
    }

    return (
        <>
            <p style={styles.imageUploadText}>
                <FormattedMessage description="MIN Form Images" defaultMessage="Images" />
            </p>
            <input
                style={styles.input}
                ref={fileInputRef}
                type="file"
                accept="image/*"
                name="myImage"
                multiple
                onChange={imageSelectHandler}
            />
            <div style={styles.imageListSlider}>
                <div style={styles.imageList}>
                    <ListWrapper>
                        <div onClick={initUpload} style={containerStyle}>
                            <BiImageAdd size={'40px'} style={styles.imageUploadIcon} />
                        </div>
                        {images.map((image, index) => (
                            <div key={image.url + index} style={styles.imageThumbContainer}>
                                <div style={styles.imageRemove} onClick={() => removeImage(image)}>
                                    <AiFillCloseCircle
                                        size={'20px'}
                                        style={styles.imageRemoveIcon}
                                    />
                                </div>
                                {image.id ? (
                                    <CldImage
                                        cloudName={process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}
                                        src={image.url}
                                        width="70"
                                        height="70"
                                        crop="fill"
                                        quality="auto"
                                        alt="MIN report image"
                                        responsive
                                        onClick={() => handleImageClick(image)}
                                    />
                                ) : (
                                    // Placeholder image
                                    <img
                                        src={formatThumbnailImageUrl(image.url)}
                                        style={styles.imageThumb}
                                    />
                                )}
                            </div>
                        ))}
                    </ListWrapper>
                </div>
            </div>
            <InfoLabel
                info={
                    <FormattedMessage
                        defaultMessage="Tap an image to add a caption."
                        description="MIN report image upload info label"
                    />
                }
            />
            <Snackbar
                open={showError}
                autoHideDuration={AUTO_HIDE_DURATION}
                anchorOrigin={TOP_CENTER}
                onClose={() => setShowError(false)}>
                <Alert elevation={6} variant="filled" severity="error">
                    <FormattedMessage
                        defaultMessage="There was an error uploading your image. Please try again."
                        description="MIN Reporting Image Upload Error Message"
                    />
                </Alert>
            </Snackbar>
            <Modal
                open={imageModalOpen}
                onClose={() => setImageModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styles.modal}>
                    {imageToBeCaptioned && (
                        <>
                            <img
                                src={formatModalImageUrl(imageToBeCaptioned.url)}
                                style={styles.imageCaptionModal}
                            />
                            <TextField
                                onChange={event => setCaption(event.target.value)}
                                value={caption}
                                fullWidth
                                label={
                                    <FormattedMessage
                                        description="MIN Form modal caption label"
                                        defaultMessage="Caption"
                                    />
                                }
                            />

                            <Box style={styles.closeBtn}>
                                <Button onClick={() => handleCancelCaption()}>
                                    <FormattedMessage
                                        description="MIN Form modal close button"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button onClick={() => handleSubmitCaption()}>
                                    <FormattedMessage
                                        description="MIN Form modal close button"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    )
}

const styles = {
    alert: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        margin: 'auto',
        padding: '0px 10px',
    },
    imageUploadText: {
        fontSize: '12px',
        margin: '0px 1px 5px',
        color: 'var(--gray)',
    },
    imageIconContainer: {
        width: '70px',
        height: '70px',
        border: '2px solid var(--gray)',
        borderRadius: '3px',
        float: 'left',
    },
    imageIconContainerClicked: {
        width: '70px',
        height: '70px',
        border: '2px solid var(--color-primary)',
        borderRadius: '3px',
        float: 'left',
    },
    imageUploadIcon: {
        color: 'var(--color-primary)',
        marginTop: '15px',
        marginLeft: '15px',
    },
    input: {
        display: 'none',
    },
    imageThumb: {
        width: '100%',
        height: '100%',
        borderRadius: '3px',
        position: 'absolute',
        zIndex: '300',
    },
    imageThumbContainer: {
        width: '70px',
        height: '70px',
        borderRadius: '3px',
        marginLeft: '10px',
        float: 'left',
        position: 'relative',
    },
    imageList: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'visible',
        marginTop: '10px',
        width: 'max-content',
    },
    imageListSlider: {
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    imageRemove: {
        with: '20px',
        height: '20px',
        position: 'absolute',
        top: '-7px',
        right: '-7px',
        cursor: 'pointer',
        zIndex: '400',
    },
    imageRemoveIcon: {
        color: 'var(--gray-darker)',
        cursor: 'pointer',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        outline: 'none',
    },
    imageCaptionModal: {
        width: '100%',
        height: 'auto',
        borderRadius: '3px',
        marginBottom: '10px',
    },
    closeBtn: {
        float: 'right',
    },
}
