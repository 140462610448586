import { defineMessages } from 'react-intl'

// This file is for messages that are used in multiple places
export default defineMessages({
    listViewNoItems: {
        id: 'listViewNoItems',
        description: 'List Views for MIN, MCR, and Accidents None found',
        defaultMessage:
            'No nearby reports found. Try zooming out or panning the map to see a different area.',
    },
    listViewNoIceClimbs: {
        id: 'listViewNoIceClimbs',
        description: 'List Views for Ice Climbs None found',
        defaultMessage:
            'No nearby ice climbs found. Try zooming out or panning the map to see a different area.',
    },
    reportCount: {
        id: 'reportCount',
        description: 'Number of reports found',
        defaultMessage: '{count, plural, one {# report found} other {# reports found}}',
    },
    stationCount: {
        id: 'stationCount',
        description: 'Number of weather stations',
        defaultMessage: '{count, plural, one {# Weather Stations} other {# Weather Stations}}',
    },
    stations: {
        id: 'stations',
        description: 'Stations',
        defaultMessage: 'Stations',
    },
    climbsCount: {
        id: 'climbsCount',
        description: 'Number of ice climbs found',
        defaultMessage: '{count, plural, one {# ice climb found} other {# ice climbs found}}',
    },
})
