import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { get } from 'lodash'

import { REPORT_STEPS } from 'components/MINReporting/constants/general'
import Shim from 'components/Shim/Shim'
import { Content } from 'components/Routing/MIN/MIN'
import { REPORT_IMAGES } from './constants/general'
import { VerticalGallery } from 'components/gallery/VerticalGallery'

export const Review = ({ reportData, setActiveStep, handleSubmit }) => {
    const images = get(reportData, REPORT_IMAGES.path)
    return (
        <>
            {reportData.observations && Object.keys(reportData.observations).length > 0 && (
                <Content payload={reportData} />
            )}
            {images && images.length > 0 && <VerticalGallery images={images} />}
            <Shim horizontal>
                <div style={styles.flex}>
                    <Button
                        style={styles.backForwardButton}
                        variant="contained"
                        startIcon={<ArrowBackIos />}
                        onClick={() => setActiveStep(REPORT_STEPS.FORM.value)}>
                        <FormattedMessage
                            description="MIN Report: Back button"
                            defaultMessage="Modify"
                        />
                    </Button>
                    <Button
                        style={styles.backForwardButton}
                        variant="contained"
                        endIcon={<ArrowForwardIos />}
                        onClick={() => handleSubmit()}>
                        <FormattedMessage
                            description="MIN Report: Submit button"
                            defaultMessage="Submit"
                        />
                    </Button>
                </div>
            </Shim>
        </>
    )
}

const styles = {
    flex: {
        display: 'flex',
        margin: '0.75rem 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
    },
    backForwardButton: {
        alignItems: 'center',
        marginBottom: '1rem',
    },
    reviewReportButton: {
        display: 'block',
        margin: 'auto',
        marginBottom: '1rem',
    },
}
