import React from 'react'

import { BottomSheet } from '../components/BottomSheet/BottomSheet'
import { LayerMenu } from 'components/LayerMenu/LayerMenu'
import MapLayout from 'components/map/MapLayout'
import { SpawAlert } from 'layouts/products/spaw'
import { Highlight } from 'components/highlight'
import {
    setBottomSheetToHalfHeight,
    DEFAULT_DRAWER_HEIGHT,
    DRAWER_FOOTER_OFFSET,
} from 'stores/BottomSheetStore'

export const ExplorerLayout = ({ children, setProduct, productID, layout, setLayout }) => {
    const defaultSnap = ({ footerHeight, maxHeight }) =>
        productID ? maxHeight * DEFAULT_DRAWER_HEIGHT : footerHeight + DRAWER_FOOTER_OFFSET

    return (
        <>
            <header>
                <SpawAlert setProduct={setProduct} />
                <Highlight />
            </header>
            <MapLayout mapClickHandler={() => handleMapClick(productID)} setProduct={setProduct}>
                <LayerMenu />
                <BottomSheet defaultSnap={defaultSnap} layout={layout} setLayout={setLayout}>
                    {children}
                </BottomSheet>
            </MapLayout>
        </>
    )
}

const handleMapClick = productID => {
    if (productID) {
        // Only open the bottom sheet if a product is selected; double-tapping the map to zoom should not open the drawer
        setBottomSheetToHalfHeight()
    }
}
