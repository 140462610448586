import React from 'react'

import { get } from 'lodash'
import TextField from '@mui/material/TextField'

import { CheckboxGroup } from 'components/MINReporting/CheckboxGroup'
import { MINSelect } from 'components/MINReporting/MINSelect'
import { Spacer } from 'components/Spacer/Spacer'
import {
    ICE_QUALITY,
    WIND_CLIMBING_CONDITIONS,
    RETREAT_CLIMB_REASON,
    CLIMB_NAME,
    RETREAT_CLIMB_REASON_OTHER,
} from './constants/quickReport'

export const IceConditionsForm = ({ report, updateReport }) => {
    return (
        <>
            <TextField
                onChange={event => updateReport(event.target.value, CLIMB_NAME.path)}
                value={get(report, CLIMB_NAME.path) || ''}
                fullWidth
                label={CLIMB_NAME.label}
                variant="outlined"
            />
            <Spacer />
            <CheckboxGroup
                options={ICE_QUALITY.options}
                label={ICE_QUALITY.label}
                selected={get(report, ICE_QUALITY.path) || []}
                onChange={value => updateReport(value, ICE_QUALITY.path)}
                singleValue
            />
            <Spacer />
            <CheckboxGroup
                singleRow
                options={WIND_CLIMBING_CONDITIONS.options}
                label={WIND_CLIMBING_CONDITIONS.label}
                selected={get(report, WIND_CLIMBING_CONDITIONS.path) || []}
                onChange={value => updateReport(value, WIND_CLIMBING_CONDITIONS.path)}
            />
            <Spacer />
            <CheckboxGroup
                singleRow
                options={RETREAT_CLIMB_REASON.options}
                label={RETREAT_CLIMB_REASON.label}
                selected={get(report, RETREAT_CLIMB_REASON.path) || []}
                onChange={value => updateReport(value, RETREAT_CLIMB_REASON.path)}
            />
            {get(report, RETREAT_CLIMB_REASON.path)?.includes('other') && (
                <TextField
                    onChange={event =>
                        updateReport(event.target.value, RETREAT_CLIMB_REASON_OTHER.path)
                    }
                    value={get(report, RETREAT_CLIMB_REASON_OTHER.path) || ''}
                    fullWidth
                    label={RETREAT_CLIMB_REASON_OTHER.label}
                    variant="outlined"
                />
            )}
        </>
    )
}
