import { parse } from 'date-fns'
import { FormattedDate, FormattedTime } from 'react-intl'
import * as Formats from '@avalanche-canada/constants/intl/formats'

export { default as Relative } from './Relative'
export { default as Range } from './Range'

export function Time({ value = new Date() }) {
    value = parse(value)
    return <FormattedTime value={value} />
}

export function DateTime({ value = new Date() }) {
    const parsed = parse(value)
    const format = isStartOfDay(parsed) ? Formats.DATE : Formats.DATETIME_SHORT

    return <FormattedDate value={parsed} {...format} />
}

export function Day({ value = new Date() }) {
    value = parse(value)

    return <FormattedDate value={value} weekday="long" />
}

export function DateElement({ value = new Date() }) {
    value = parse(value)

    return <FormattedDate value={value} {...Formats.DATE} />
}

// Utils
function isStartOfDay(date) {
    return (
        date.getHours() === 0 &&
        date.getMinutes() === 0 &&
        date.getSeconds() === 0 &&
        date.getMilliseconds() === 0
    )
}
