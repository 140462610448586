import { ListItem as ListItemMUI, ListItemButton, ListItemText, Grid } from '@mui/material'
import { FiUser } from 'react-icons/fi'
import { Landscape } from '@mui/icons-material/'

import { Relative } from 'components/time'

// This is formatted to follow the MIN data structure, all other data types will have to conform to that structure
export const ListItem = ({ Icon, select, id, title, datetime, username, region }) => {
    return (
        <ListItemMUI
            key={id}
            disablePadding
            onClick={() => {
                select(id)
            }}
            sx={style.listItem}>
            <ListItemButton>
                <ListItemText
                    component="div"
                    primary={<PrimaryText Icon={Icon} title={title} datetime={datetime} />}
                    secondary={
                        username && region && <SecondaryText username={username} region={region} />
                    }
                />
            </ListItemButton>
        </ListItemMUI>
    )
}

export const PrimaryText = ({ Icon, title, datetime }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={7.5} sx={style.truncateText}>
                {title}
            </Grid>
            <Grid item xs={4.5} sx={{ ...style.iconFlex, justifyContent: 'flex-end' }}>
                <Icon width="16" height="16" />
                <span style={style.truncateText}>
                    <Relative value={datetime} />
                </span>
            </Grid>
        </Grid>
    )
}

export const SecondaryText = ({ username, region }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={7.5} style={style.iconFlex}>
                <FiUser /> {username}
            </Grid>
            <Grid item xs={4.5} style={{ ...style.iconFlex, justifyContent: 'flex-end' }}>
                <Landscape />
                <div style={style.truncateText}>{region}</div>
            </Grid>
        </Grid>
    )
}

const style = {
    iconFlex: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.3em',
    },
    listItem: {
        borderTop: '1px solid var(--color-step-150)',
    },
    truncateText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}
