import * as React from 'react'

import useSWR from 'swr'

import * as Auth from 'contexts/authentication'
import Client from 'clients/min'
import { useLocale } from 'stores/SettingsStore'

const { client } = Client

export function useUserSubmissions(params = {}) {
    const locale = useLocale()
    const key = [
        'submissions',
        locale,
        Object.entries(params)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .flat(7),
    ].flat()
    const { getAccessTokenSilently, user } = Auth.useAuth()
    const userid = user.sub

    return useSWR(key, () =>
        Client.submissions({ ...params, userid }, locale, getAccessTokenSilently)
    )
}

export function useDeleteSubmission() {
    const locale = useLocale()

    const { getAccessTokenSilently } = Auth.useAuth()

    return React.useCallback(
        id => {
            const path = [locale, 'submissions', id]

            return client.delete(path, getAccessTokenSilently)
        },
        [locale, getAccessTokenSilently]
    )
}

export function useUndoDeleteSubmission() {
    const locale = useLocale()

    const { getAccessTokenSilently } = Auth.useAuth()

    return React.useCallback(
        id => {
            const path = [locale, 'submissions', id, 'undelete']

            return client.post(path, null, getAccessTokenSilently)
        },
        [locale, getAccessTokenSilently]
    )
}

export function useSaveSubmission() {
    const locale = useLocale()

    const { getAccessTokenSilently } = Auth.useAuth()

    return React.useCallback(
        data => {
            const { submissionID } = data
            const path = [locale, 'submissions', submissionID]
            const save = submissionID ? client.put : client.post

            return save.call(client, path, data, getAccessTokenSilently)
        },
        [locale, getAccessTokenSilently]
    )
}

export function usePostImages() {
    const locale = useLocale()

    return React.useCallback(
        (...images) => {
            const path = [locale, 'images']

            images = images.flat()

            return client.post(path, { images })
        },
        [locale]
    )
}

export function uploadImage(url, file) {
    return fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
            'content-type': file.type,
        },
    })
}
