import React from 'react'

import {
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Switch,
} from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Shim from 'components/Shim/Shim'
import { LOCALE_TEXTS } from 'services/intl/messages'
import {
    useLocale,
    useSetLocale,
    useColorBlindMode,
    useSetColorBlindMode,
} from 'stores/SettingsStore'
import { Stack } from '@mui/system'

export const Settings = () => {
    const locale = useLocale()
    const setLocale = useSetLocale()
    const colorblindMode = useColorBlindMode()
    const setColorBlindMode = useSetColorBlindMode()

    return (
        <Shim horizontal vertical>
            <Stack spacing={2}>
                <FormControl>
                    <FormLabel id="locale-settings">
                        <FormattedMessage
                            defaultMessage="Language"
                            description="Language settings"
                        />
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="locale-settings"
                        onChange={event => setLocale(event.target.value)}
                        defaultValue={locale}>
                        {[...LOCALE_TEXTS].map(([key, value]) => (
                            <FormControlLabel
                                control={<Radio />}
                                key={key}
                                value={key}
                                label={value}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <Box sx={style.flex}>
                    <FormLabel id="colorblind-settings">
                        <FormattedMessage
                            defaultMessage="Colorblind-safe map colors"
                            description="Settings page"
                        />
                    </FormLabel>
                    <Switch
                        defaultChecked={colorblindMode}
                        value={colorblindMode}
                        onChange={event => setColorBlindMode(event.target.checked)}
                    />
                </Box>
            </Stack>
        </Shim>
    )
}

const style = {
    flex: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
}
