import { useMemo } from 'react'

import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { subDays } from 'date-fns'
import {
    createFeatureCollection,
    filterFeatureCollection,
} from '@avalanche-canada/utils/products/min'
import { differenceInCalendarDays } from 'date-fns'
import { DateParam } from '@avalanche-canada/utils/params'
import { MAX as DAYS } from '@avalanche-canada/constants/products/min/days'
import { split } from '@avalanche-canada/utils/products/min/types'
import { create } from '@avalanche-canada/utils/lib/filter'
import { filter } from '@avalanche-canada/utils/lib/array'
import { MOUNTAIN_INFORMATION_NETWORK } from '@avalanche-canada/constants/products'
import { useMapState } from 'contexts/map/state'

import { useLayer } from 'contexts/layers'
import SchemasClient from 'clients/min/schemas'
import client from 'clients/min'
import { useLocale } from 'stores/SettingsStore'

export function useSubmission(id) {
    const locale = useLocale()

    const key = ['submissions', id, locale]

    return useSWR(key, () => client.submission(id, locale))
}

export function useMINSubmissions(params = defaultParams, options) {
    const locale = useLocale()
    const key = [
        'submissions',
        locale,
        Object.entries(params)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .flat(7),
    ].flat()

    const { data: submissions } = useSWR(key, () => client.submissions(params, locale), options)

    return {
        submissions: useMemo(() => {
            if (!submissions) {
                return null
            }

            return submissions.items.data.map(formatSubmission)
        }),
        features: useMemo(() => {
            if (!submissions) {
                return null
            }

            return createFeatureCollection(submissions.items.data)
        }, [submissions]),
    }
}

export function useFilteredMINSubmissions() {
    const { viewportBounds } = useMapState()
    const layer = useLayer(MOUNTAIN_INFORMATION_NETWORK)
    const { days, types } = layer.filters
    const params = { age: days, ...split(types) }
    const filters = create(params, ReportFilters)
    const { submissions, features } = useMINSubmissions()

    return {
        submissions: useMemo(() => {
            if (!submissions) {
                return null
            }

            return geographicallyFilterSubmissions(
                filter(submissions, filters),
                viewportBounds.value
            )
        }, [submissions, filters, viewportBounds]),
        features: useMemo(() => {
            if (!features) {
                return null
            }

            return filterFeatureCollection(features, params)
        }, [features, params]),
    }
}

export function useDisplaySchema(version) {
    const locale = useLocale()

    const key = ['min', 'schemas', 'display', locale, version]

    return useSWRImmutable(key, () => SchemasClient.getDisplay(version, locale))
}

function formatSubmission({ observations, tags, datetime, ...rest }) {
    const age = differenceInCalendarDays(new Date(), new Date(datetime))
    const properties = { age, tags, ...observations }

    return {
        ...rest,
        datetime,
        properties,
    }
}
function geographicallyFilterSubmissions(submissions, bounds) {
    if (!bounds || !bounds._sw || !bounds._ne || !bounds._sw || !bounds._ne) {
        return submissions
    }
    return submissions.filter(
        ({ location }) =>
            location.latitude >= bounds._sw.lat &&
            location.longitude >= bounds._sw.lng &&
            location.latitude <= bounds._ne.lat &&
            location.longitude <= bounds._ne.lng
    )
}

// Constants
const fromdate = DateParam.format(subDays(new Date(), DAYS))
const defaultParams = { fromdate, pagesize: 1000 }
const ReportFilters = new Map([
    ['age', age => report => report.properties.age <= age],
    [
        'obtypes',
        obtypes => {
            const obTypes = Array.from(obtypes)

            return report => obTypes.some(obtype => report.properties[obtype] > 0)
        },
    ],
    ['tags', tags => report => report.properties.tags.some(tag => tags.has(tag))],
])
