import * as React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Panel from 'components/panel'
import Shim from 'components/Shim/Shim'
import { StructuredText } from 'prismic/components/base'
import { useDisclaimers } from 'hooks/data/disclaimers'
import { useLocale } from 'stores/SettingsStore'

import styles from './Forecast.module.css'
import { VerticalGallery } from 'components/gallery/VerticalGallery'

Footer.propTypes = {
    children: PropTypes.node,
}

export default function Footer({ children }) {
    return (
        <footer className={styles.Footer}>
            {children || (
                <React.Fragment>
                    <DangerRatings />
                    <Disclaimer />
                </React.Fragment>
            )}
        </footer>
    )
}

export function Disclaimer({ uid = 'forecast-disclaimer', expanded }) {
    return <Prismic uid={uid} expanded={expanded} />
}

export function Prismic({ uid, expanded }) {
    const { disclaimers } = useDisclaimers()
    const { data } = disclaimers.find(disclaimer => disclaimer.uid === uid)

    return (
        <Panel header={data.title} expanded={expanded}>
            <Shim horizontal>
                <StructuredText value={data.body} />
            </Shim>
        </Panel>
    )
}

export function DangerRatings({ expanded }) {
    const locale = useLocale()
    const header = (
        <FormattedMessage defaultMessage="Danger Ratings Explained" description="FX Footer" />
    )
    const images =
        locale === 'fr'
            ? [
                  {
                      url: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1701903639/website/NAPADS_fr.png',
                      height: 2338,
                      width: 3134,
                      caption: 'Échelle publique nord-américaine de danger d’avalanche',
                  },
              ]
            : [
                  {
                      url: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1702507147/website/NAPADS.png',
                      height: 2677,
                      width: 3727,
                      caption: 'North American Public Avalanche Danger Scale',
                  },
              ]

    return (
        <Panel header={header} expanded={expanded}>
            <VerticalGallery images={images} cloudName="avalanche-ca" />
        </Panel>
    )
}
