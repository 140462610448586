import React, { useState } from 'react'

import { get } from 'lodash'
import { TextField, Box, Button, Typography, Modal } from '@mui/material'
import { BsInfoCircle } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'

import {
    OBSERVATION_TYPE,
    ELEVATION,
    ELEVATAION_BAND,
    ASPECT,
    SNOWPACK_DEPTH,
    WHUMPFING,
    CRACKING,
    FOOT_PENETRATION,
    SKI_PENETRATION,
    SLED_PENETRATION,
    SNOWPACK_TEST_RESULT,
    TEST_FRACTURE_CHARACTER,
    TEST_FAILURE_DEPTH,
    SURFACE_CONDITIONS,
    TEST_FAILURE_CRYSTAL_TYPE,
    COMMENT,
} from './constants/snowpackReport'
import { getError, HelperText, outputPotentiallyZero, numberHandler } from './helpers'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Spacer } from 'components/Spacer/Spacer'
import { CheckboxGroup } from './CheckboxGroup'
import { InfoLabel } from './InfoLabel'
import { MINCommentInput } from './MINCommentInput'
import { CheckboxYesNo } from './CheckboxYesNo'

const basePath = 'observations.snowpack'

export const SnowpackReport = ({ report, updateReport }) => {
    const [openSizeModal, setOpenSizeModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        title: null,
        info: null,
    })

    const showInfoModal = (title, info) => {
        setModalContent({ title, info })
        setOpenSizeModal(true)
    }

    return (
        <DetailsWrapper>
            <CheckboxGroup
                options={OBSERVATION_TYPE.options}
                label={OBSERVATION_TYPE.label}
                selected={get(report, OBSERVATION_TYPE.path) || []}
                onChange={value => updateReport(value, OBSERVATION_TYPE.path)}
                singleRow
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(
                                OBSERVATION_TYPE.description.title,
                                OBSERVATION_TYPE.description.info
                            )
                        }
                    />
                }
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, ELEVATION.path, updateReport)}
                value={outputPotentiallyZero(report, ELEVATION.path)}
                error={getError(report, ELEVATION)}
                helperText={<HelperText field={ELEVATION} report={report} basePath={basePath} />}
                type="number"
                fullWidth
                label={ELEVATION.label}
                variant="outlined"
            />
            <Spacer />
            <CheckboxGroup
                options={ELEVATAION_BAND.options}
                label={ELEVATAION_BAND.label}
                selected={get(report, ELEVATAION_BAND.path) || []}
                onChange={value => updateReport(value, ELEVATAION_BAND.path)}
                singleRow
            />
            <CheckboxGroup
                options={ASPECT.options}
                label={ASPECT.label}
                selected={get(report, ASPECT.path) || []}
                onChange={value => updateReport(value, ASPECT.path)}
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, SNOWPACK_DEPTH.path, updateReport)
                }
                value={outputPotentiallyZero(report, SNOWPACK_DEPTH.path)}
                error={getError(report, SNOWPACK_DEPTH)}
                helperText={
                    <HelperText field={SNOWPACK_DEPTH} report={report} basePath={basePath} />
                }
                type="number"
                fullWidth
                label={SNOWPACK_DEPTH.label}
                variant="outlined"
            />
            <CheckboxYesNo
                label={WHUMPFING.label}
                onChange={value => updateReport(value, WHUMPFING.path)}
                value={get(report, WHUMPFING.path)}
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(WHUMPFING.description.title, WHUMPFING.description.info)
                        }
                    />
                }
            />
            <CheckboxYesNo
                label={CRACKING.label}
                onChange={value => updateReport(value, CRACKING.path)}
                value={get(report, CRACKING.path)}
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(CRACKING.description.title, CRACKING.description.info)
                        }
                    />
                }
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, FOOT_PENETRATION.path, updateReport)
                }
                value={outputPotentiallyZero(report, FOOT_PENETRATION.path)}
                error={getError(report, FOOT_PENETRATION)}
                helperText={
                    <HelperText field={FOOT_PENETRATION} report={report} basePath={basePath} />
                }
                type="number"
                fullWidth
                label={FOOT_PENETRATION.label}
                variant="outlined"
            />
            <InfoLabel info={FOOT_PENETRATION.description} />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, SKI_PENETRATION.path, updateReport)
                }
                value={outputPotentiallyZero(report, SKI_PENETRATION.path)}
                error={getError(report, SKI_PENETRATION)}
                helperText={
                    <HelperText field={SKI_PENETRATION} report={report} basePath={basePath} />
                }
                type="number"
                fullWidth
                label={SKI_PENETRATION.label}
                variant="outlined"
            />
            <InfoLabel info={SKI_PENETRATION.description} />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, SLED_PENETRATION.path, updateReport)
                }
                value={outputPotentiallyZero(report, SLED_PENETRATION.path)}
                error={getError(report, SLED_PENETRATION)}
                helperText={
                    <HelperText field={SLED_PENETRATION} report={report} basePath={basePath} />
                }
                type="number"
                fullWidth
                label={SLED_PENETRATION.label}
                variant="outlined"
            />
            <InfoLabel info={FOOT_PENETRATION.description} />
            <CheckboxGroup
                options={SNOWPACK_TEST_RESULT.options}
                label={SNOWPACK_TEST_RESULT.label}
                selected={get(report, SNOWPACK_TEST_RESULT.path) || []}
                onChange={value => updateReport(value, SNOWPACK_TEST_RESULT.path)}
                singleValue
            />
            <InfoLabel info={SNOWPACK_TEST_RESULT.description} />
            <CheckboxGroup
                options={TEST_FRACTURE_CHARACTER.options}
                label={TEST_FRACTURE_CHARACTER.label}
                selected={get(report, TEST_FRACTURE_CHARACTER.path) || []}
                onChange={value => updateReport(value, TEST_FRACTURE_CHARACTER.path)}
                singleValue
                singleRow
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, TEST_FAILURE_DEPTH.path, updateReport)
                }
                value={outputPotentiallyZero(report, TEST_FAILURE_DEPTH.path)}
                error={getError(report, TEST_FAILURE_DEPTH)}
                helperText={
                    <HelperText field={TEST_FAILURE_DEPTH} report={report} basePath={basePath} />
                }
                type="number"
                fullWidth
                label={TEST_FAILURE_DEPTH.label}
                variant="outlined"
            />
            <InfoLabel info={TEST_FAILURE_DEPTH.description} />
            <CheckboxGroup
                options={SURFACE_CONDITIONS.options}
                label={SURFACE_CONDITIONS.label}
                selected={get(report, SURFACE_CONDITIONS.path) || []}
                onChange={value => updateReport(value, SURFACE_CONDITIONS.path)}
            />
            <CheckboxGroup
                options={TEST_FAILURE_CRYSTAL_TYPE.options}
                label={TEST_FAILURE_CRYSTAL_TYPE.label}
                selected={get(report, TEST_FAILURE_CRYSTAL_TYPE.path) || []}
                onChange={value => updateReport(value, TEST_FAILURE_CRYSTAL_TYPE.path)}
            />
            <Spacer />
            <MINCommentInput
                onChange={value => updateReport(value, COMMENT.path)}
                value={get(report, COMMENT.path)}
            />
            <Modal
                open={openSizeModal}
                onClose={() => setOpenSizeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styles.modal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalContent.title}
                    </Typography>
                    {Array.isArray(modalContent.info) ? (
                        modalContent.info.map(info => <p>{info}</p>)
                    ) : (
                        <p>{modalContent.info}</p>
                    )}
                    <Button style={styles.closeBtn} onClick={() => setOpenSizeModal(false)}>
                        <FormattedMessage
                            description="MIN Form modal close button"
                            defaultMessage="Close"
                        />
                    </Button>
                </Box>
            </Modal>
        </DetailsWrapper>
    )
}

const styles = {
    closeBtn: {
        float: 'right',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    previewButton: {
        display: 'block',
        margin: '0px auto 20px',
    },
}
