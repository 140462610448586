import React, { useState } from 'react'

import { List } from '@mui/material'
import { ListItem as ListItemMUI, Popover, Box } from '@mui/material'
import { Tune } from '@mui/icons-material'
import { Button } from '@mui/material'

import messages from 'services/intl/messageDefinitions'
import { ListItem } from 'components/BottomSheet/ListItem/ListItem'
import { MountainInformationNetwork } from 'components/icons'
import { FormattedMessage } from 'react-intl'
import Shim from 'components/Shim/Shim'
import { Filters } from './Filters'
import { useFilteredMINSubmissions } from 'hooks/data/MIN'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Back } from 'components/button/Back'

export const MINTable = ({ select }) => {
    const { submissions } = useFilteredMINSubmissions()
    const [filterPopoverRef, setPopoverRef] = useState(null)

    const openFilterPopover = event => {
        setPopoverRef(event.currentTarget)
    }

    const handleFilterPopoverClose = () => {
        setPopoverRef(null)
    }

    const open = Boolean(filterPopoverRef)
    const id = open ? 'simple-popover' : undefined

    return (
        <>
            <Shim horizontal>
                <Box sx={style.headerSection}>
                    <Back />
                    <Button variant="outlined" onClick={openFilterPopover} startIcon={<Tune />}>
                        <FormattedMessage defaultMessage="Filters" description="MIN List View" />
                    </Button>
                    <div style={style.count}>
                        <FormattedMessage
                            {...messages.reportCount}
                            values={{ count: submissions.length }}
                        />
                    </div>
                </Box>
            </Shim>
            <Popover
                id={id}
                open={open}
                anchorEl={filterPopoverRef}
                onClose={handleFilterPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <Filters />
            </Popover>
            <DetailsWrapper>
                <List>
                    {submissions.length === 0 ? (
                        <ListItemMUI>
                            <FormattedMessage {...messages.listViewNoItems} />
                        </ListItemMUI>
                    ) : (
                        submissions.map(item => {
                            return (
                                <ListItem
                                    key={item.id}
                                    Icon={MountainInformationNetwork}
                                    select={select}
                                    id={item.id}
                                    title={item.title}
                                    datetime={item.datetime}
                                    username={item.username}
                                    region={item.region}
                                />
                            )
                        })
                    )}
                </List>
            </DetailsWrapper>
        </>
    )
}

const style = {
    headerSection: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5em',
    },
    count: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}
