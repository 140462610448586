import { useRef } from 'react'

import Shim from 'components/Shim/Shim'
import { useForecasts } from 'hooks/data/forecasts'
import { useProductID } from 'stores/ViewStore'
import { useTrackSectionTimeOnScreen } from 'hooks/useTrackSectionTimeOnScreen'

import styles from './Forecast.module.css'

export const Summary = ({ title, children }) => {
    const ref = useRef(null)
    useTrackSectionTimeOnScreen(ref)

    const productID = useProductID()
    const { forecasts } = useForecasts()
    const product = forecasts.find(forecast => forecast.id === productID)
    const { id, dateIssued, forecaster, validUntil, title: fxTitle, highlights } = product || {}

    return (
        <section
            className={styles.Summary}
            ref={ref}
            data-forecast-section={title}
            data-event-name={title}
            data-forecast-id={id}
            data-date-issued={dateIssued}
            data-valid-until={validUntil}
            data-forecaster={forecaster}
            data-highlights={highlights}
            data-fx-regions={fxTitle}>
            <div style={stylesObj.header}>
                <h3 style={stylesObj.h3}>{title}</h3>
            </div>
            <Shim horizontal>{children}</Shim>
        </section>
    )
}

const stylesObj = {
    header: {
        backgroundColor: 'var(--color-step-100)',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1em',
        width: '100%',
        marginBottom: '1em',
        marginTop: '2em',
    },
    h3: {
        color: 'var(--text-color)',
        marginTop: '1em',
        marginBottom: '1em',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    },
}
