import { defineMessages } from 'react-intl'
import * as Ratings from '@avalanche-canada/constants/products/forecast/ratings'

export const TravelAdvices = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Travel Advice message for EXTREME',
        defaultMessage: 'Avoid all avalanche terrain.',
    },
    [Ratings.HIGH]: {
        description: 'Travel Advice message for HIGH',
        defaultMessage:
            'Very dangerous avalanche conditions. Travel in avalanche terrain not recommended.',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Travel Advice message for CONSIDERABLE',
        defaultMessage:
            'Dangerous avalanche conditions. Careful snowpack evaluation, cautious route-finding and conservative decision-making essential.',
    },
    [Ratings.MODERATE]: {
        description: 'Travel Advice message for MODERATE',
        defaultMessage:
            'Heightened avalanche conditions on specific terrain features. Evaluate snow and terrain carefully; identify features of concern.',
    },
    [Ratings.LOW]: {
        description: 'Travel Advice message for LOW',
        defaultMessage:
            'Generally safe avalanche conditions. Watch for unstable snow on isolated terrain features.',
    },
    [Ratings.NO_FORECAST]: {
        description: 'Travel Advice message for NO_FORECAST',
        defaultMessage:
            'The location you selected is outside the current avalanche forecast area. Basic avalanche safety information applies to all snow covered slopes, including the location you selected.',
    },
    [Ratings.SPRING]: {
        description: 'Travel Advice message for SPRING',
        defaultMessage:
            'The avalanche danger is variable and can range from Low to High. Travelling early in the day is recommended, as conditions can change rapidly in short periods of time due to daytime warming.',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Travel Advice message for EARLY_SEASON',
        defaultMessage:
            'Insufficient observations exist to reliably rate the avalanche danger. Expect shallow snow cover with thinly buried rocks and trees just beneath the surface. Avalanche danger often concentrates in gullies and other deposition areas, where wind slabs may bond poorly to a weak underlying base.',
    },
    [Ratings.OFF_SEASON]: {
        description: 'Travel Advice message for OFF_SEASON',
        defaultMessage:
            'Avalanche forecasts have ended for the season and will return with the snow in the fall. Look for the first one sometime in November. Field Observations for your area may be available at the Association of Canadian Mountain Guides (ACMG) Mountain Conditions Report Website: {link1}. More detailed information on managing conditions in the spring and summer: {link2}.',
        // This should also get translated but it's not translated in the old site - packages/utils/products/forecasts/messageFormatter.js
        values: {
            link1: (
                <a href="https://www.mountainconditions.ca" target="_blank">
                    Mountain Conditions Report
                </a>
            ),
            link2: (
                <a href="https://www.avalanche.ca/spring-conditions" target="_blank">
                    Spring Conditions
                </a>
            ),
        },
    },
})

export const LikehoodOfAvalanche = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Likelihood of Avalanche message for EXTREME',
        defaultMessage: 'Natural and human-triggered avalanches certain.',
    },
    [Ratings.HIGH]: {
        description: 'Likelihood of Avalanche message for HIGH',
        defaultMessage: 'Natural avalanches likely; human-triggered avalanches very likely.',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Likelihood of Avalanche message for CONSIDERABLE',
        defaultMessage: 'Natural avalanches possible; human-triggered avalanches likely.',
    },
    [Ratings.MODERATE]: {
        description: 'Likelihood of Avalanche message for MODERATE',
        defaultMessage: 'Natural avalanches unlikely; human-triggered avalanches possible.',
    },
    [Ratings.LOW]: {
        description: 'Likelihood of Avalanche message for LOW',
        defaultMessage: 'Natural and human-triggered avalanches unlikely.',
    },
    // TODO: Add messages for the following ratings
    [Ratings.NO_FORECAST]: {
        description: 'Likelihood of Avalanche message for NO_FORECAST',
        defaultMessage: 'TBD',
    },
    [Ratings.SPRING]: {
        description: 'Likelihood of Avalanche message for SPRING',
        defaultMessage: 'TBD',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Likelihood of Avalanche message for EARLY_SEASON',
        defaultMessage: 'TBD',
    },
    [Ratings.OFF_SEASON]: {
        description: 'Likelihood of Avalanche message for OFF_SEASON',
        defaultMessage: 'TBD',
    },
})

export const SizeAndDistribution = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Size and Distribution message for EXTREME',
        defaultMessage: 'Large to very large avalanches in many areas.',
    },
    [Ratings.HIGH]: {
        description: 'Size and Distribution message for HIGH',
        defaultMessage:
            'Large avalanches in many areas; or very large avalanches in specific areas.',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Size and Distribution message for CONSIDERABLE',
        defaultMessage:
            'Small avalanches in many areas; or large avalanches in specific areas; or very large avalanches in isolated areas.',
    },
    [Ratings.MODERATE]: {
        description: 'Size and Distribution message for MODERATE',
        defaultMessage:
            'Small avalanches in specific areas; or large avalanches in isolated areas.',
    },
    [Ratings.LOW]: {
        description: 'Size and Distribution message for LOW',
        defaultMessage: 'Small avalanches in isolated areas or extreme terrain.',
    },
    // TODO: Add messages for the following ratings
    [Ratings.NO_FORECAST]: {
        description: 'Size and Distribution message for NO_FORECAST',
        defaultMessage: 'TBD',
    },
    [Ratings.SPRING]: {
        description: 'Size and Distribution message for SPRING',
        defaultMessage: 'TBD',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Size and Distribution message for EARLY_SEASON',
        defaultMessage: 'TBD',
    },
    [Ratings.OFF_SEASON]: {
        description: 'Size and Distribution message for OFF_SEASON',
        defaultMessage: 'TBD',
    },
})

export const Names = defineMessages({
    [Ratings.EXTREME]: {
        description: 'Ratings Texts for EXTREME',
        defaultMessage: '5 - Extreme',
    },
    [Ratings.HIGH]: {
        description: 'Ratings Texts for HIGH',
        defaultMessage: '4 - High',
    },
    [Ratings.CONSIDERABLE]: {
        description: 'Ratings Texts for CONSIDERABLE',
        defaultMessage: '3 - Considerable',
    },
    [Ratings.MODERATE]: {
        description: 'Ratings Texts for MODERATE',
        defaultMessage: '2 - Moderate',
    },
    [Ratings.LOW]: {
        description: 'Ratings Texts for LOW',
        defaultMessage: '1 - Low',
    },
    [Ratings.NO_RATING]: {
        description: 'Ratings Texts for NO_RATING',
        defaultMessage: '0 - No Rating',
    },
    [Ratings.NO_FORECAST]: {
        description: 'Ratings Texts for NO_FORECAST',
        defaultMessage: '0 - No Forecast',
    },
    [Ratings.SPRING]: {
        description: 'Ratings Texts for SPRING',
        defaultMessage: '0 - Spring',
    },
    [Ratings.EARLY_SEASON]: {
        description: 'Ratings Texts for EARLY_SEASON',
        defaultMessage: '0 - Early Season',
    },
    [Ratings.OFF_SEASON]: {
        description: 'Ratings Texts for OFF_SEASON',
        defaultMessage: '0 - Off Season',
    },
})
