import * as Products from '@avalanche-canada/constants/products'
import { INCIDENT } from '@avalanche-canada/constants/products/min/types'

export default new Map([
    [
        Products.FORECAST,
        {
            fill: {
                paint: {
                    'fill-color': ['get', 'colour'],
                    'fill-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        //change opacity depending on whether region is selected or not
                        3,
                        ['case', ['boolean', ['feature-state', 'active'], false], 0.8, 0.6],
                        8,
                        ['case', ['boolean', ['feature-state', 'active'], false], 0.4, 0.2],
                        14,
                        0,
                    ],
                },
            },
            line: {
                paint: {
                    'line-color': '#000',
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        3,
                        ['boolean', ['feature-state', 'active'], false],
                        3,
                        1,
                    ],
                },
            },
            symbol: {
                layout: {
                    'text-field': ['get', 'name'],
                    'text-size': 10,
                },
                paint: {
                    'text-color': '#B43A7E',
                    'text-halo-color': 'hsl(0, 0%, 100%)',
                    'text-halo-width': 1,
                },
            },
        },
    ],
    [
        Products.WEATHER_STATION,
        {
            symbol: {
                layout: {
                    'icon-image': 'weather-station',
                    'icon-allow-overlap': true,
                    'icon-size': 0.9,
                    'icon-padding': 10,
                    'text-font': ['Open Sans Extrabold'],
                    'text-field': ['get', 'point_count'],
                    'text-size': 10,
                    'text-offset': [-0.75, 0],
                },
                paint: {
                    'text-color': '#000000',
                    'text-halo-color': '#FFFFFF',
                    'text-halo-width': 2,
                },
            },
        },
    ],
    [
        Products.MOUNTAIN_CONDITIONS_REPORT,
        {
            symbol: {
                layout: {
                    'icon-image': 'mountain-conditions-report',
                    'icon-allow-overlap': true,
                    'icon-size': 0.9,
                    'icon-padding': 10,
                    'text-font': ['Open Sans Extrabold'],
                    'text-field': ['get', 'point_count'],
                    'text-size': 10,
                    'text-offset': [-0.75, -0.9],
                },
                paint: {
                    'text-color': '#1996BA',
                    'text-halo-color': '#FFFFFF',
                    'text-halo-width': 2,
                },
            },
        },
    ],
    [
        Products.ACCIDENT,
        {
            symbol: {
                layout: {
                    'icon-image': 'fatal-accident',
                    'icon-allow-overlap': true,
                    'icon-size': 0.9,
                    'icon-padding': 10,
                    'text-font': ['Open Sans Extrabold'],
                    'text-field': ['get', 'point_count'],
                    'text-size': 10,
                    'text-offset': [-0.75, -0.8],
                },
                paint: {
                    'text-color': '#000000',
                    'text-halo-color': '#FFFFFF',
                    'text-halo-width': 2,
                },
            },
        },
    ],
    [
        Products.MOUNTAIN_INFORMATION_NETWORK,
        {
            symbol: {
                layout: {
                    'icon-image': [
                        'case',
                        ['>', ['get', INCIDENT], 0],
                        'min-pin-with-incident',
                        'min-pin',
                    ],
                    'icon-size': ['case', ['boolean', ['get', 'cluster'], false], 1, 0.9],
                    'icon-padding': 10,
                    'icon-allow-overlap': true,
                    'text-field': ['get', 'point_count'],
                    'text-offset': [-0.8, -1.1],
                    'text-size': 10,
                },
                paint: {
                    'text-color': '#03A9F4',
                    'text-halo-color': '#FFFFFF',
                    'text-halo-width': 2,
                },
            },
        },
    ],
    [
        Products.CLOSURE_ZONE,
        {
            fill: {
                paint: {
                    'fill-color': [
                        'match',
                        ['get', 'type'],
                        ['prohibited'],
                        'hsla(356, 100%, 45%, 0.8)',
                        'hsla(356, 100%, 45%, 0.3)',
                    ],
                },
            },
            symbol: {
                paint: {
                    'text-color': 'hsl(0, 0%, 100%)',
                    'text-opacity': 1,
                    'text-halo-color': 'hsl(356, 100%, 45%)',
                    'text-halo-width': 0.8,
                    'text-halo-blur': 0.5,
                },
                layout: {
                    'text-field': ['get', 'label_en'],
                    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 11, 18, 14],
                    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                    'text-line-height': 1.2,
                    'text-max-width': 10,
                    'text-justify': 'center',
                    'text-padding': 2,
                    'text-anchor': 'center',
                    'text-offset': [0, 0],
                    'symbol-placement': 'point',
                    'symbol-avoid-edges': false,
                    'text-allow-overlap': false,
                    'text-ignore-placement': false,
                },
            },
        },
    ],
    [
        Products.SPAW,
        {
            line: {
                paint: {
                    'line-color': 'red',
                    'line-width': 3,
                },
            },
            fill: {
                paint: {
                    // Important: SPAW images like `spaw-24.svg` must be uploaded to Mapbox Studio > Images for the corresponding Mapbox style. These files are in `src/components/icons/spaw`.
                    'fill-pattern': ['step', ['zoom'], 'spaw-24', 8, 'spaw-64'],
                    'fill-opacity': ['interpolate', ['linear'], ['zoom'], 3, 1, 10, 0],
                },
            },
        },
    ],
    [
        Products.ICE_CLIMB,
        {
            symbol: {
                layout: {
                    'icon-image': 'ice-climbing-atlas',
                    'icon-padding': 10,
                    'icon-allow-overlap': true,
                    'icon-size': 0.9,
                    'text-font': ['Open Sans Extrabold'],
                    'text-field': ['get', 'point_count'],
                    'text-size': 10,
                    'text-offset': [-0.75, -0.9],
                },
                paint: {
                    'text-color': '#000000',
                    'text-halo-color': '#FFFFFF',
                    'text-halo-width': 2,
                },
            },
        },
    ],
])
