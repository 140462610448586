import React from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { FormattedMessage } from 'react-intl'
import { MOUNTAIN_INFORMATION_NETWORK } from '@avalanche-canada/constants/products'

import { DAYS } from '@avalanche-canada/constants/products/min/days'
import { useReportTypeOptions } from 'constants/min-messages'
import { useLayer } from 'contexts/layers'

export const Filters = () => {
    const reportTypeOptions = useReportTypeOptions()
    const { filters, setFilterValue } = useLayer(MOUNTAIN_INFORMATION_NETWORK)

    const handleDateRangeChange = event => {
        setFilterValue('days', event.target.value)
    }
    const handleReportTypeChange = event => {
        setFilterValue('types', event.target.value)
    }

    return (
        <Box sx={styles.wrapper}>
            <Stack spacing={3}>
                <FormControl>
                    <InputLabel id="date-range-label">
                        <FormattedMessage defaultMessage="Date Range" description="MIN Filters" />
                    </InputLabel>
                    <Select
                        labelId="date-range-label"
                        value={filters.days}
                        label="Date Range"
                        onChange={handleDateRangeChange}>
                        {DAYS.map(amount => (
                            <MenuItem key={amount} value={amount}>
                                <FormattedMessage
                                    description="Layout main map menu"
                                    defaultMessage="{amount, plural, one {# day} other {# days}}"
                                    values={{ amount }}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="report-type-label">
                        <FormattedMessage defaultMessage="Report Type" description="MIN Filters" />
                    </InputLabel>
                    <Select
                        labelId="report-type-label"
                        multiple
                        displayEmpty
                        value={[...filters.types]}
                        label="Report Type"
                        onChange={handleReportTypeChange}>
                        {[...reportTypeOptions].map(([value, text], index) => (
                            <MenuItem key={index} value={value}>
                                {text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </Box>
    )
}

const styles = {
    wrapper: { minWidth: 200, padding: 2 },
}
