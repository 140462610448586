import { useEffect } from 'react'

import { iceClimbs } from '@avalanche-canada/constants/products/ice-climbs'
import { ICE_CLIMB_OPENED } from '@avalanche-canada/constants/products/mixpanel'
import mixpanel from 'mixpanel-browser'
import { FormattedMessage } from 'react-intl'

import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { Offline } from 'components/text'
import { HeaderWithLocate } from 'components/text/HeaderWithLocate'
import { useIceClimb } from 'hooks/ice-climbs'
import SliceZone from 'prismic/components/base/SliceZone'

export const IceClimb = ({ id }) => {
    const { data: document, isLoading, isError } = useIceClimb(id)
    const location = iceClimbs.features.find(route => route.properties.id === id).geometry
        .coordinates

    useEffect(() => {
        mixpanel.track(ICE_CLIMB_OPENED, { id })
    }, [id])

    if (!navigator.onLine) {
        return (
            <Shim horizontal>
                <Offline />
            </Shim>
        )
    }

    if (isLoading) {
        return (
            <>
                <Shim horizontal>
                    <FormattedMessage
                        defaultMessage="Loading ice climb…"
                        description="Layout drawers/IceClimb"
                    />
                </Shim>
            </>
        )
    }

    if (!isLoading && isError) {
        return (
            <>
                <Shim horizontal>
                    <FormattedMessage
                        defaultMessage="Ice climb not found"
                        description="Layout drawers/IceClimb"
                    />
                </Shim>
            </>
        )
    }

    return (
        <DetailsWrapper>
            <HeaderWithLocate text={document.data?.title} location={location} />
            <Shim horizontal>
                {document && Array.isArray(document.data.content) && (
                    <SliceZone value={document.data.content} />
                )}
            </Shim>
        </DetailsWrapper>
    )
}
