import { FormattedMessage } from 'react-intl'

import { AVALANCHE_FIELDS } from './avalancheReport'
import { INCIDENT_FIELDS } from './incidentReport'
import { QUICK_FIELDS } from './quickReport'
import { SNOWPACK_FIELDS } from './snowpackReport'
import { WEATHER_FIELDS } from './weatherReport'
import {
    RIDING_QUALITY,
    SNOW_CONDITIONS,
    RIDE_TYPE,
    STAYED_AWAY,
    ICE_QUALITY,
    WIND_CLIMBING_CONDITIONS,
    RETREAT_CLIMB_REASON,
    RETREAT_CLIMB_REASON_OTHER,
} from './quickReport'

export const SNOW_CONDITIONS_FIELDS = [
    RIDING_QUALITY.path,
    SNOW_CONDITIONS.path,
    RIDE_TYPE.path,
    STAYED_AWAY.path,
]
export const ICE_CONDITIONS_FIELDS = [
    ICE_QUALITY.path,
    WIND_CLIMBING_CONDITIONS.path,
    RETREAT_CLIMB_REASON.path,
    RETREAT_CLIMB_REASON_OTHER.path,
]
export const REQUIRED_FIELDS = ['title', 'location', 'observations', 'datetime', 'tags']
export const VALID_FIELDS = [
    'title',
    'location',
    'observations',
    'datetime',
    'tags',
    'images',
    'submissionID',
]

export const REPORT_TYPES = {
    QUICK: 'quick',
    AVALANCHE: 'avalanche',
    SNOWPACK: 'snowpack',
    WEATHER: 'weather',
    INCIDENT: 'incident',
}

export const REPORT_STEPS = {
    FORM: {
        label: <FormattedMessage description="MIN form steps" defaultMessage="Form" />,
        value: 0,
    },
    REVIEW: {
        label: <FormattedMessage description="MIN form steps" defaultMessage="Review" />,
        value: 1,
    },
    SUBMIT: {
        label: <FormattedMessage description="MIN form steps" defaultMessage="Submit" />,
        value: 2,
    },
}

// TODO:: REFERENCE STRINGS IN TRANSLATION FILE
export const REPORT_TYPES_DESCRIPTIONS = {
    quick: (
        <FormattedMessage
            description={'MIN form Quick Report description'}
            defaultMessage={
                'Use the Quick Report to quickly share information about your trip. You can create a comprehensive report by adding more details in the Avalanche, Snowpack, Weather, and/or Incident tabs.'
            }
        />
    ),
    avalanche: (
        <FormattedMessage
            description={'MIN form Avalanche Report description'}
            defaultMessage={
                'Share information about a single, notable avalanche or tell us about overall avalanche conditions by describing many avalanches in a general sense. Aspect, elevation, trigger, dimensions/size are key data.'
            }
        />
    ),
    snowpack: (
        <FormattedMessage
            description={'MIN form Snowpack Report description'}
            defaultMessage={
                'Snowpack depth, layering, and bonding are key data. Test results are very useful.'
            }
        />
    ),
    weather: (
        <FormattedMessage
            description={'MIN form Weather Report description'}
            defaultMessage={
                'Key data includes information about current and accumulated precipitation, wind speed and direction, temperatures, and cloud cover.'
            }
        />
    ),
    incident: (
        <FormattedMessage
            description={'MIN form Incident Report description'}
            defaultMessage={
                'Sharing incidents can help us all learn. Describe close calls and accidents here. Be sensitive to the privacy of others. Before reporting serious accidents check our <link>submission guidelines</link>.'
            }
            values={{
                link(text) {
                    return (
                        <a
                            href="https://www.avalanche.ca/mountain-information-network/submission-guidelines"
                            target="_blank"
                            rel="noopener noreferrer">
                            {text}
                        </a>
                    )
                },
            }}
        />
    ),
}

export const REPORT_TITLE = {
    path: 'title',
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Report Name" />,
    required: true,
}

export const REPORT_DATE = {
    path: 'datetime',
    label: (
        <FormattedMessage
            description="MIN Form Field"
            defaultMessage="When were you in the backcountry?"
        />
    ),
    required: true,
}

export const REPORT_IMAGES = {
    path: 'images',
}

// This is used by the segmented control to select the type of report
export const TAGS = {
    path: 'tags',
    SNOW_CONDITIONS: 'snowConditions',
    ICE_CONDITIONS: 'iceConditions',
}

export const LATITUDE = {
    path: 'location.latitude',
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Latitude" />,
    required: true,
}

export const LONGITUDE = {
    path: 'location.longitude',
    label: <FormattedMessage description="MIN Form Field" defaultMessage="Longitude" />,
    required: true,
}

export const REPORT_TYPE_FIELDS = {
    [REPORT_TYPES.QUICK]: QUICK_FIELDS,
    [REPORT_TYPES.AVALANCHE]: AVALANCHE_FIELDS,
    [REPORT_TYPES.SNOWPACK]: SNOWPACK_FIELDS,
    [REPORT_TYPES.WEATHER]: WEATHER_FIELDS,
    [REPORT_TYPES.INCIDENT]: INCIDENT_FIELDS,
}

export const SCHEMA_VERSIONS = {
    dataSchemaId: 'data_1.1.1',
    displaySchemaId: 'ui_display_1.1.1',
    formSchemaId: 'ui_form_1.1.1',
}

export const ERROR_MESSAGES = {
    offline: (
        <FormattedMessage
            defaultMessage="You are offline. Try submitting when you have a data connection."
            description="MIN form error"
        />
    ),
    errorNotLoggedIn: (
        <FormattedMessage
            description="MIN form error"
            defaultMessage="You must be logged in to submit a report"
        />
    ),
    errorSubmitting: (
        <FormattedMessage
            description="MIN form error"
            defaultMessage="There was an error submitting your report"
        />
    ),
    errorMissingFields: (
        <FormattedMessage
            description="MIN form error"
            defaultMessage="Please fill out all required fields"
        />
    ),
}
