import React from 'react'

import { motion } from 'framer-motion'

export const ListWrapper = ({ children }) => {
  return (
    <motion.div layout transition={spring}>
        {children}
    </motion.div>
  )
}

const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
    duration: 0.2
}