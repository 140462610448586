import React, { isValidElement } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import { TextField, InputAdornment } from '@mui/material'
import { Search, Close } from '@mui/icons-material'

import { useSearchTerm } from 'hooks/useSearchTerm'
import { Place } from 'components/icons/Place'
import { OptionSet } from './OptionSet'
import * as MapContext from 'contexts/map'
import * as Async from 'contexts/async'
import { useBoolean } from 'hooks'
import { useFlyTo } from 'hooks/useFlyTo'
import { PRIMARY } from 'constants/colors'

import css from './MapGeocoder.module.css'

export const MapGeocoder = () => {
    const intl = useIntl()
    const placeholder = intl.formatMessage({
        description: 'Geocoder',
        defaultMessage: 'Search Places…',
    })

    const [active, activate, deactivate] = useBoolean(false)
    const [term, setTerm] = React.useState('')
    const [termCount, setTermCount] = React.useState(0)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [lngLat, setLngLat] = React.useState(null)
    const terms = useSearchTerm(term)
    const hasTerm = Boolean(term)

    const flyTo = useFlyTo()

    const onFocus = () => {
        if (!hasTerm) {
            activate()
        }
    }

    const onKeyDown = event => {
        // Down
        if (event.keyCode === 40) {
            event.preventDefault()
            setSelectedIndex(calculateSelectedIndex(selectedIndex, termCount, 1))
        }

        // Up
        if (event.keyCode === 38) {
            event.preventDefault()
            setSelectedIndex(calculateSelectedIndex(selectedIndex, termCount, 0))
        }

        // Enter
        if (event.keyCode === 13) {
            event.preventDefault()
            if (hasTerm) {
                handleOptionClick(terms.data[selectedIndex])
                deactivate()
                setSelectedIndex(0)
            }
        }

        // Escape
        if (event.keyCode === 27) {
            deactivate()
        }
    }

    const handleChange = event => {
        const { value } = event.target
        activate()
        setTerm(value)
    }

    const handleOptionClick = place => {
        const { latitude, longitude } = place
        deactivate()
        setTerm(place.name)
        setLngLat({ lng: longitude, lat: latitude })
        flyTo([longitude, latitude])
    }

    const handleClearClick = () => {
        deactivate()
        setTerm('')
        setLngLat(null)
    }

    const InputAdornments = {
        startAdornment: (
            <InputAdornment position="start">
                <Search />
            </InputAdornment>
        ),
        endAdornment: (
            <InputAdornment position="end">
                {term && (
                    <button className={css.Clear} onClick={handleClearClick}>
                        <Close />
                    </button>
                )}
            </InputAdornment>
        ),
    }

    return (
        <div className={css.Container}>
            <div className={css.Control}>
                <TextField
                    type="text"
                    sx={{
                        '& .MuiInputBase-root': {
                            borderRadius: '100px',
                            maxHeight: '45px',
                        },
                    }}
                    className={css.Input}
                    placeholder={placeholder}
                    value={term}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={deactivate}
                    InputProps={InputAdornments}
                />
            </div>
            <Async.Provider value={terms}>
                <Async.Found>
                    {terms => {
                        if (!hasTerm || !active) {
                            return null
                        }

                        if (terms.length === 0) {
                            return (
                                <div className={clsx(css.BoxWithShadow, css.PaddedText)}>
                                    <FormattedMessage
                                        defaultMessage="No results found"
                                        description="Map geocoder search results"
                                    />
                                </div>
                            )
                        }

                        setTermCount(terms.length)

                        return (
                            <div className={css.BoxWithShadow}>
                                <OptionSet onChange={handleOptionClick}>
                                    {terms.map((place, index) => {
                                        const selected = index === selectedIndex

                                        return (
                                            <Option
                                                key={place.id}
                                                value={place}
                                                selected={selected}>
                                                {place.name}
                                            </Option>
                                        )
                                    })}
                                </OptionSet>
                            </div>
                        )
                    }}
                </Async.Found>
            </Async.Provider>
            {lngLat && (
                <MapContext.Marker lnglat={lngLat}>
                    <Place color={PRIMARY} />
                </MapContext.Marker>
            )}
        </div>
    )
}

const Option = ({ children, value, selected, onClick = noop }) => {
    const title = isValidElement(children) ? value : children
    const name = selected ? 'Option--Selected' : 'Option'

    const handleMouseDown = () => {
        onClick(value)
    }

    return (
        <div title={title} onMouseDown={handleMouseDown} className={css[name]}>
            {children}
        </div>
    )
}

// Helpers

const calculateSelectedIndex = (currentIndex, max, add = true) => {
    if (add) {
        // Start over when hitting the max
        if (currentIndex + 1 > max - 1) {
            return 0
        } else {
            return currentIndex + 1
        }
    } else {
        // Start at the max when running out
        if (currentIndex - 1 < 0) {
            return max - 1
        } else {
            return currentIndex - 1
        }
    }
}
