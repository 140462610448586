import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { REPORT_TYPES } from 'components/MINReporting/constants/general'
import { isValidReport } from 'components/MINReporting/helpers'
import { Spacer } from 'components/Spacer/Spacer'
import Shim from 'components/Shim/Shim'
import { ReportTypeControl } from 'components/MINReporting/ReportTypeControl'
import { QuickReport } from 'components/MINReporting/QuickReport'
import { BasicInfo } from 'components/MINReporting/BasicInfo'
import { AvalancheReport } from 'components/MINReporting/AvalancheReport'
import { SnowpackReport } from 'components/MINReporting/SnowpackReport'
import { WeatherReport } from 'components/MINReporting/WeatherReport'
import { IncidentReport } from 'components/MINReporting/IncidentReport'
import { useEditingMIN } from 'stores/ViewStore'

export const Form = ({
    reportType,
    updateReportType,
    reportDescription,
    updateReport,
    reportData,
    handleClickReviewButton,
    handleClickClearButton,
}) => {
    const editingMIN = useEditingMIN()
    return (
        <>
            <ReportTypeControl value={reportType} setValue={updateReportType} />
            <Shim horizontal>
                <BasicInfo
                    reportDescription={reportDescription}
                    updateReport={updateReport}
                    report={reportData}
                />
                {reportType === REPORT_TYPES.QUICK && (
                    <QuickReport report={reportData} updateReport={updateReport} />
                )}
                {reportType === REPORT_TYPES.AVALANCHE && (
                    <AvalancheReport report={reportData} updateReport={updateReport} />
                )}
                {reportType === REPORT_TYPES.SNOWPACK && (
                    <SnowpackReport report={reportData} updateReport={updateReport} />
                )}
                {reportType === REPORT_TYPES.WEATHER && (
                    <WeatherReport report={reportData} updateReport={updateReport} />
                )}
                {reportType === REPORT_TYPES.INCIDENT && (
                    <IncidentReport report={reportData} updateReport={updateReport} />
                )}
            </Shim>
            <Spacer />
            <Button
                disabled={!isValidReport(reportData)}
                style={styles.reportButton}
                variant="contained"
                onClick={() => handleClickReviewButton()}>
                <FormattedMessage
                    description="MIN Report: Review button"
                    defaultMessage="Review My Report"
                />
            </Button>
            {editingMIN === null && (
                <Button
                    style={styles.reportButton}
                    variant="outline"
                    onClick={() => handleClickClearButton()}>
                    <FormattedMessage
                        description="MIN Report: Reset button"
                        defaultMessage="Clear Values"
                    />
                </Button>
            )}
        </>
    )
}

const styles = {
    reportButton: {
        display: 'block',
        margin: 'auto',
        marginBottom: '1rem',
    },
}
