import React from 'react'

import { List, ListItem as ListItemMUI, Box } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import messages from 'services/intl/messageDefinitions'
import { useFilteredWeatherStations } from 'hooks/data/weather'
import Shim from 'components/Shim/Shim'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Back } from 'components/button/Back'
import { WeatherStationListItem } from './WeatherStationListItem'

export const WeatherStationTable = ({ select }) => {
    const { stations } = useFilteredWeatherStations()
    const stationCount = stations.length

    return (
        <>
            <Shim horizontal>
                <Box
                    sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    <Back />
                    <div>
                        <FormattedMessage
                            {...messages.stationCount}
                            values={{ count: stationCount }}
                        />
                    </div>
                </Box>
            </Shim>
            <DetailsWrapper>
                <div style={{ marginTop: '15px' }}>
                    <List>
                        {stations.length === 0 ? (
                            <ListItemMUI>
                                <FormattedMessage
                                    defaultMessage="No nearby weather stations found. Try zooming out or panning the map to see a different area."
                                    description="Weather stations table"
                                />
                            </ListItemMUI>
                        ) : (
                            stations.map(({ stationId, name, source, elevation }) => (
                                <WeatherStationListItem
                                    key={`station-${stationId}`}
                                    title={name}
                                    source={source}
                                    onClick={() => select(stationId)}
                                    elevation={elevation}
                                />
                            ))
                        )}
                    </List>
                </div>
            </DetailsWrapper>
        </>
    )
}
