import React, { useState } from 'react'

import { get } from 'lodash'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
    TextField,
    Box,
    Button,
    Typography,
    Modal,
    InputAdornment,
    IconButton,
} from '@mui/material'
import { BsInfoCircle } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import ClearIcon from '@mui/icons-material/Clear'

import { getError, HelperText, outputPotentiallyZero, numberHandler } from './helpers'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import { Spacer } from 'components/Spacer/Spacer'
import { CheckboxGroup } from './CheckboxGroup'
import { InfoLabel } from './InfoLabel'
import { MINCommentInput } from './MINCommentInput'
import {
    CLOUD_COVER,
    PRECIPTATION_TYPE,
    SNOWFALL_RATE,
    RAINFALL_RATE,
    TEMPERATURE,
    MIN_TEMPERATURE,
    MAX_TEMPERATURE,
    TEMPERATURE_CHANGE,
    NEW_SNOW,
    NEW_SNOW_AND_RAIN,
    RECENT_STORM_SNOW,
    RECENT_STORM_DATE,
    WIND_SPEED,
    WIND_DIRECTION,
    BLOWING_SNOW,
    COMMENT,
} from './constants/weatherReport'

const basePath = 'observations.weather'

export const WeatherReport = ({ report, updateReport }) => {
    const isAndroid = /android/i.test(navigator.userAgent.toLowerCase())
    const [openSizeModal, setOpenSizeModal] = useState(false)
    const [modalContent, setModalContent] = useState({
        title: null,
        info: null,
        type: null,
    })

    const showInfoModal = (title, info, type = null) => {
        setModalContent({ title, info, type })
        setOpenSizeModal(true)
    }

    const clearDate = (path, event) => {
        event.stopPropagation()
        updateReport(null, path)
    }

    const Adornment = ({ path }) => {
        return (
            get(report, path) && (
                <InputAdornment onClick={event => clearDate(path, event)} position="end">
                    <IconButton>
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            )
        )
    }

    return (
        <DetailsWrapper>
            <CheckboxGroup
                options={CLOUD_COVER.options}
                label={CLOUD_COVER.label}
                selected={get(report, CLOUD_COVER.path) || []}
                onChange={value => updateReport(value, CLOUD_COVER.path)}
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(
                                CLOUD_COVER.description.title,
                                CLOUD_COVER.description.info
                            )
                        }
                    />
                }
            />
            <CheckboxGroup
                options={PRECIPTATION_TYPE.options}
                label={PRECIPTATION_TYPE.label}
                selected={get(report, PRECIPTATION_TYPE.path) || []}
                onChange={value => updateReport(value, PRECIPTATION_TYPE.path)}
                singleRow
                singleValue
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, SNOWFALL_RATE.path, updateReport)
                }
                value={outputPotentiallyZero(report, SNOWFALL_RATE.path)}
                error={getError(report, SNOWFALL_RATE)}
                helperText={
                    <HelperText field={SNOWFALL_RATE} report={report} basePath={basePath} />
                }
                fullWidth
                type="number"
                label={SNOWFALL_RATE.label}
                variant="outlined"
            />
            <InfoLabel info={SNOWFALL_RATE.description.info} />
            <CheckboxGroup
                options={RAINFALL_RATE.options}
                label={RAINFALL_RATE.label}
                selected={get(report, RAINFALL_RATE.path) || []}
                onChange={value => updateReport(value, RAINFALL_RATE.path)}
                singleRow
                singleValue
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, TEMPERATURE.path, updateReport)
                }
                value={outputPotentiallyZero(report, TEMPERATURE.path)}
                error={getError(report, TEMPERATURE)}
                helperText={<HelperText field={TEMPERATURE} report={report} basePath={basePath} />}
                fullWidth
                type={isAndroid ? 'tel' : 'number'}
                label={TEMPERATURE.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, MIN_TEMPERATURE.path, updateReport)
                }
                value={outputPotentiallyZero(report, MIN_TEMPERATURE.path)}
                error={getError(report, MIN_TEMPERATURE)}
                helperText={
                    <HelperText field={MIN_TEMPERATURE} report={report} basePath={basePath} />
                }
                fullWidth
                type={isAndroid ? 'tel' : 'number'}
                label={MIN_TEMPERATURE.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, MAX_TEMPERATURE.path, updateReport)
                }
                value={outputPotentiallyZero(report, MAX_TEMPERATURE.path)}
                error={getError(report, MAX_TEMPERATURE)}
                helperText={
                    <HelperText field={MAX_TEMPERATURE} report={report} basePath={basePath} />
                }
                fullWidth
                type={isAndroid ? 'tel' : 'number'}
                label={MAX_TEMPERATURE.label}
                variant="outlined"
            />
            <CheckboxGroup
                options={TEMPERATURE_CHANGE.options}
                label={TEMPERATURE_CHANGE.label}
                selected={get(report, TEMPERATURE_CHANGE.path) || []}
                onChange={value => updateReport(value, TEMPERATURE_CHANGE.path)}
                singleRow
                singleValue
            />
            <Spacer />
            <TextField
                onChange={event => numberHandler(event.target.value, NEW_SNOW.path, updateReport)}
                value={outputPotentiallyZero(report, NEW_SNOW.path)}
                error={getError(report, NEW_SNOW)}
                helperText={<HelperText field={NEW_SNOW} report={report} basePath={basePath} />}
                fullWidth
                type="number"
                label={NEW_SNOW.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, NEW_SNOW_AND_RAIN.path, updateReport)
                }
                value={outputPotentiallyZero(report, NEW_SNOW_AND_RAIN.path)}
                error={getError(report, NEW_SNOW_AND_RAIN)}
                helperText={
                    <HelperText field={NEW_SNOW_AND_RAIN} report={report} basePath={basePath} />
                }
                fullWidth
                type="number"
                label={NEW_SNOW_AND_RAIN.label}
                variant="outlined"
            />
            <Spacer />
            <TextField
                onChange={event =>
                    numberHandler(event.target.value, RECENT_STORM_SNOW.path, updateReport)
                }
                value={outputPotentiallyZero(report, RECENT_STORM_SNOW.path)}
                error={getError(report, RECENT_STORM_SNOW)}
                helperText={
                    <HelperText field={RECENT_STORM_SNOW} report={report} basePath={basePath} />
                }
                fullWidth
                type="number"
                label={RECENT_STORM_SNOW.label}
                variant="outlined"
            />
            <InfoLabel info={RECENT_STORM_SNOW.description.info} />
            <Spacer />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={RECENT_STORM_DATE.label}
                    value={get(report, RECENT_STORM_DATE.path) || null}
                    onChange={value =>
                        updateReport(
                            value ? value.toISOString().split('T')[0] : null,
                            RECENT_STORM_DATE.path
                        )
                    }
                    InputProps={{
                        endAdornment: <Adornment path={RECENT_STORM_DATE.path} />,
                    }}
                    renderInput={params => (
                        <TextField required={RECENT_STORM_DATE.required} fullWidth {...params} />
                    )}
                />
            </LocalizationProvider>
            <InfoLabel info={RECENT_STORM_DATE.description.info} />
            <CheckboxGroup
                options={WIND_SPEED.options}
                label={WIND_SPEED.label}
                selected={get(report, WIND_SPEED.path) || []}
                onChange={value => updateReport(value, WIND_SPEED.path)}
                singleRow
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(
                                WIND_SPEED.description.title,
                                WIND_SPEED.description.info,
                                WIND_SPEED.description.type
                            )
                        }
                    />
                }
            />
            <CheckboxGroup
                options={WIND_DIRECTION.options}
                label={WIND_DIRECTION.label}
                selected={get(report, WIND_DIRECTION.path) || []}
                onChange={value => updateReport(value, WIND_DIRECTION.path)}
                singleValue
            />
            <CheckboxGroup
                options={BLOWING_SNOW.options}
                label={BLOWING_SNOW.label}
                selected={get(report, BLOWING_SNOW.path) || []}
                onChange={value => updateReport(value, BLOWING_SNOW.path)}
                singleRow
                singleValue
                icon={
                    <BsInfoCircle
                        onClick={() =>
                            showInfoModal(
                                BLOWING_SNOW.description.title,
                                BLOWING_SNOW.description.info,
                                BLOWING_SNOW.description.type
                            )
                        }
                    />
                }
            />

            <Spacer />
            <MINCommentInput
                onChange={value => updateReport(value, COMMENT.path)}
                value={get(report, COMMENT.path)}
            />
            <Modal
                open={openSizeModal}
                onClose={() => setOpenSizeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styles.modal}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalContent.title}
                    </Typography>
                    {modalFormatter(modalContent)}
                    <Button style={styles.closeBtn} onClick={() => setOpenSizeModal(false)}>
                        <FormattedMessage
                            description="MIN Form modal close button"
                            defaultMessage="Close"
                        />
                    </Button>
                </Box>
            </Modal>
        </DetailsWrapper>
    )
}

// utils and helpers
const modalFormatter = modalContent => {
    if (modalContent.type === 'bullets') {
        return (
            <ul>
                {modalContent.info.map(info => (
                    <li>{info}</li>
                ))}
            </ul>
        )
    }

    return Array.isArray(modalContent.info) ? (
        modalContent.info.map(info => <p>{info}</p>)
    ) : (
        <p>{modalContent.info}</p>
    )
}

const styles = {
    closeBtn: {
        float: 'right',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    previewButton: {
        display: 'block',
        margin: '0px auto 20px',
    },
}
