import React from 'react'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

export const MINSelect = ({ options, label, selected, onChange }) => {
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={selected}
                    label={label}
                    onChange={event => onChange(event.target.value)}>
                    {options.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
