import { useEffect } from 'react'

import { MCR_OPENED } from '@avalanche-canada/constants/products/mixpanel'
import { FormattedMessage } from 'react-intl'
import mixpanel from 'mixpanel-browser'

import DateSet from 'components/MCR/DateSet'
import Location from 'components/MCR/Location'
import Media from 'components/MCR/Media'
import Submitter from 'components/MCR/Submitter'
import { DetailsWrapper } from 'components/Motion/DetailsWrapper'
import Shim from 'components/Shim/Shim'
import { HeaderWithLocate } from 'components/text/HeaderWithLocate'
import { useMountainConditionsReport } from 'hooks/data/MCR'

export const MCR = ({ id }) => {
    const { report } = useMountainConditionsReport(id)

    useEffect(() => {
        mixpanel.track(MCR_OPENED, { id })
    }, [id])

    if (!report) {
        return (
            <DetailsWrapper>
                <Shim horizontal>
                    <FormattedMessage description="MCR Not Found" defaultMessage="MCR Not Found" />
                </Shim>
            </DetailsWrapper>
        )
    }

    const { title, dates, location, body, images, location_desc, user, groups } = report
    const { image, name } = user

    return (
        <>
            <DetailsWrapper>
                <header>
                    <HeaderWithLocate text={title} location={location} />
                    <Shim horizontal>
                        <DateSet values={dates} />
                        {location_desc && <Location location={location_desc} />}
                        <Submitter name={name} image={image} groups={groups} />
                    </Shim>
                </header>
                <Shim horizontal>
                    <div dangerouslySetInnerHTML={{ __html: body }}></div>
                </Shim>
                {images && <Media images={images} />}
            </DetailsWrapper>
        </>
    )
}
