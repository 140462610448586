import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Muted } from 'components/text'
import Table from './Table'
import * as Columns from './columns'
import * as Headers from './headers'
import { useIntlMemo } from 'hooks/intl'
import Shim from 'components/Shim/Shim'

Station.propTypes = {
    measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function Station({ measurements }) {
    const columns = useColumns()
    const headers = useHeaders()

    if (measurements.length === 0) {
        return (
            <Shim horizontal>
                <Muted>
                    <FormattedMessage
                        description="Component weather/station/Station"
                        defaultMessage="This station currently has no data available."
                    />
                </Muted>
            </Shim>
        )
    }

    return <Table measurements={measurements} columns={columns} headers={headers} />
}

// Utils
function useHeaders() {
    return useIntlMemo(intl => [
        [
            Headers.createSnow(intl),
            Headers.createAirTemperature(intl),
            Headers.createWind(intl),
            Headers.createRelativeHumidity(intl),
        ],
        [
            Headers.createSnowHeight(intl),
            Headers.createNewSnow(intl),
            Headers.createAirTemperatureAvg(intl),
            Headers.createWindSpeedAvg(intl),
            Headers.createWindDirectionAvg(intl),
            Headers.createWindSpeedGust(intl),
        ],
    ])
}
function useColumns() {
    return useIntlMemo(intl => [
        Columns.createHour(intl),
        Columns.createSnowHeight(intl),
        Columns.createNewSnow(intl),
        Columns.createAirTemperatureAvg(intl),
        Columns.createWindSpeedAvg(intl),
        Columns.createWindDirectionAvg(intl),
        Columns.createWindSpeedGust(intl),
        Columns.createRelativeHumidity(intl),
    ])
}
