import React from 'react'

import CloseIcon from '@mui/icons-material/Close'

import styles from './CloseButton.module.css'

export const CloseButton = ({ callback }) => {
    return (
        <button className={styles.Button} onClick={callback}>
            <CloseIcon />
        </button>
    )
}
