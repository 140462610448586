import { useState, useEffect } from 'react'
import clsx from 'clsx'

import css from './SegmentedControl.module.css'

//TODO:: option to set styles to right, left or center aligned (default to center)
export const SegmentedControl = ({ name, segments, callback, active, controlRef }) => {
    const [activeValue, setActiveValue] = useState(active)

    useEffect(() => {
        const activeSegment = segments.find(segment => segment.value === activeValue)
        const activeSegmentRef = activeSegment.ref
        const { offsetWidth, offsetLeft } = activeSegmentRef.current
        const { style } = controlRef.current

        style.setProperty('--highlight-width', `${offsetWidth}px`)
        style.setProperty('--highlight-x-pos', `${offsetLeft}px`)
    }, [activeValue, callback, controlRef, segments])

    const onInputChange = (value, index) => {
        setActiveValue(value)
        callback(value, index)
    }

    return (
        <div className={css.controlsContainer} ref={controlRef}>
            <div className={css.controls}>
                {segments?.map((item, i) => (
                    <div
                        key={item.value}
                        className={clsx(
                            css.segment,
                            item.value === activeValue ? css.active : css.inactive
                        )}
                        ref={item.ref}>
                        <input
                            type="radio"
                            value={item.value}
                            id={item.label}
                            name={name}
                            onChange={() => onInputChange(item.value, i)}
                            checked={item.value === activeValue}
                        />
                        <label htmlFor={item.label}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}
