import { useRef, useMemo } from 'react'

import { useIntl } from 'react-intl'
import { getCoord } from '@turf/invariant'
import * as Geolocation from '@avalanche-canada/utils/dom/geolocation'

import * as MapState from 'contexts/map/state'
import * as MapContext from 'contexts/map'
import * as Layers from './layers'
import { TitleMessages } from 'constants/products/layers'
import { useForecasts } from 'hooks/data/forecasts'
import 'mapbox-gl/dist/mapbox-gl.css'
import { SPAW } from '@avalanche-canada/constants/products'

const env = process.env.NODE_ENV

export default function Map({ mapClickHandler, showLayers, setProduct, children }) {
    const intl = useIntl()
    const { zoom, center, errors, viewportBounds } = MapState.useMapState()
    const options = useRef()
    const { areas } = useForecasts()

    options.current = MapState.useOptions(options)

    const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0

    // TODO: Make this dynamic with sheet height. Right now it gives padding 2/3 up the screen,
    // which should be fine when sheetHeight is 0, 0.25 and 0.5.
    const fitBoundsOptionsForGeolocate = useMemo(() => {
        return {
            fitBoundsOptions: {
                maxZoom: 10,
                padding: {
                    top: 0.33 * windowHeight * 0.9, // 0.9 avoids the top and bottom padding being > 1 which causes an error
                    bottom: 0.66 * windowHeight,
                    left: 0,
                    right: 0,
                },
            },
        }
    }, [windowHeight])

    // TODO Move that logic to contexts/map
    const once = useMemo(
        () => ({
            async load(event) {
                const map = event.target
                if (env === 'development') {
                    window.map = map
                }

                if (
                    zoom.value === null &&
                    center.value === null
                    // 'lat' in query &&
                    // 'lng' in query
                ) {
                    try {
                        const position = await Geolocation.getCurrentPosition()
                        const bounds = MapState.getBounds(position.coords)

                        map.fitBounds(bounds, fitBoundsOptions)
                    } catch (error) {
                        // We do not need to do anything with that!
                        // Required to avoid "onunhandledrejection" error!
                    }
                }
            },
        }),
        []
    )

    const on = useMemo(
        () => ({
            zoomend(event) {
                const value = event.target.getZoom()

                zoom.set(value)
            },
            moveend(event) {
                const value = event.target.getCenter()
                viewportBounds.set(event.target.getBounds())

                center.set(value)
            },
            error(event) {
                errors.add(MapState.ERRORS.MAP, event.error)
            },
            click(event) {
                const { target: map } = event
                if (mapClickHandler) {
                    mapClickHandler(event, map)
                }
            },
        }),
        []
    )

    const onLayerEvents = useMemo(() => {
        let counter = 0

        return {
            mouseenter({ target }) {
                const canvas = target.getCanvas()

                canvas.style.cursor = 'pointer'
                counter++
            },
            mouseleave({ target }) {
                const canvas = target.getCanvas()

                counter--

                if (counter < 1) {
                    canvas.style.cursor = ''
                }

                canvas.title = ''
            },
            mousemove(event) {
                // "mousemove" is the best way to handle title!
                // "mouseenter" does not work as well as "mousemove"
                const canvas = event.target.getCanvas()
                const [{ properties }] = event.features
                let title = properties.name || properties.title || ''

                if (properties.cluster) {
                    const { product, point_count } = properties
                    const label = intl.formatMessage(TitleMessages[product])

                    title = point_count + ' ' + label
                }

                canvas.title = title
            },
            click(event) {
                const { point, target: map } = event
                const features = map.queryRenderedFeatures(point)

                if (features.length === 0) {
                    return
                }

                const [feature] = features
                const { properties } = feature

                if (properties.cluster) {
                    const source = map.getSource(feature.source)

                    source.getClusterExpansionZoom(properties.cluster_id, (error, zoom) => {
                        if (error) {
                            // We do not really care if there is an error,
                            // we will just zoom in a bit so user receives a
                            // feedback to the click on the cluster!
                            zoom = map.getZoom() + 1
                        }

                        const center = getCoord(feature)

                        map.flyTo({
                            center,
                            zoom,
                        })
                    })
                } else {
                    if ('url' in properties) {
                        const { url, id } = properties

                        window.open(url, id)
                    } else {
                        const { product, id } = properties
                        if (product === SPAW) {
                            const forecastFeature = features.find(
                                feature => feature.layer.id === 'forecast'
                            )
                            if (forecastFeature) {
                                const { properties: underlyingForecastProperties } = forecastFeature
                                setProduct(
                                    underlyingForecastProperties.product,
                                    underlyingForecastProperties.id
                                )
                                const polygon = areas.features.find(area => {
                                    return area.properties.id === underlyingForecastProperties.id
                                })
                                zoomToPolygon(map, polygon)
                            }
                        } else {
                            setProduct(product, id)
                        }

                        const polygon = areas.features.find(area => {
                            return area.properties.id === feature.id
                        })

                        zoomToPolygon(map, polygon)
                    }
                }
            },
        }
    }, [intl])

    return (
        <div style={styles.map}>
            <MapContext.Map options={options.current} on={on} once={once}>
                <MapContext.NavigationControl position="top-right" />
                <MapContext.GeolocateControl
                    options={fitBoundsOptionsForGeolocate}
                    position="top-right"
                />
                {showLayers && (
                    <MapContext.WithMapReady>
                        <Layers.ClosureZones />
                        <Layers.ForecastAreas on={onLayerEvents} />
                        <Layers.SPAW on={onLayerEvents} />
                        <Layers.WeatherStations on={onLayerEvents} />
                        <Layers.MountainConditionReports on={onLayerEvents} />
                        <Layers.MountainInformationNetwork on={onLayerEvents} />
                        <Layers.FatalAccidents on={onLayerEvents} />
                        <Layers.IceClimbing on={onLayerEvents} />
                    </MapContext.WithMapReady>
                )}
                {children}
            </MapContext.Map>
        </div>
    )
}

const styles = {
    map: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
}

// Constants
const fitBoundsOptions = {
    padding: 25,
}

const zoomToPolygon = (map, polygon) => {
    if (!polygon) {
        return null
    }
    map.fitBounds(polygon.bbox, {
        padding: {
            top: 50,
            bottom: 200,
            left: 50,
            right: 50,
        },
    })
}
